
/**
 * Custom hook for Google Analytics tracking
 */

// Define interface for the window object with gtag
declare global {
  interface Window {
    gtag: (
      command: string,
      action: string,
      params?: {
        page_title?: string;
        page_location?: string;
        page_path?: string;
        send_to?: string;
        [key: string]: any;
      }
    ) => void;
    dataLayer: any[];
  }
}

/**
 * Track a page view in Google Analytics
 * @param path - The page path to track
 * @param title - The page title
 */
export const trackPageView = (path: string, title?: string) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('config', 'G-M29Y75VHK8', {
      page_path: path,
      page_title: title
    });
    console.log(`Analytics: tracked page view for ${path}`);
  }
};

/**
 * Track a custom event in Google Analytics
 * @param action - The event action
 * @param category - The event category
 * @param label - The event label
 * @param value - The event value
 */
export const trackEvent = (
  action: string,
  category?: string,
  label?: string,
  value?: number
) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value
    });
    console.log(`Analytics: tracked event ${action}`);
  }
};

/**
 * Hook to initialize Google Analytics tracking
 */
export const useAnalytics = () => {
  return {
    trackPageView,
    trackEvent
  };
};

export default useAnalytics;
