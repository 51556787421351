
import React, { useEffect, useState } from "react";
import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { AuthProvider } from "@/context/AuthContext";
import { SubmissionProvider } from "@/context/SubmissionContext";
import RequireAuth from "@/components/RequireAuth";
import { trackPageView } from "@/hooks/useAnalytics";

// Pages
import Index from "./pages/Index";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Dashboard from "./pages/Dashboard";
import Admin from "./pages/Admin";
import Upgrade from "./pages/Upgrade";
import Submission from "./pages/Submission";
import NotFound from "./pages/NotFound";

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // Prevent refetching data when window regains focus
      retry: 1, // Limit retry attempts
    },
  },
});

// ScrollToTop component to handle scrolling to top on route changes
const ScrollToTop = () => {
  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return null;
};

// Analytics tracker component
const RouteTracker = () => {
  const { pathname } = useLocation();
  
  useEffect(() => {
    // Track page view on route change
    trackPageView(pathname);
  }, [pathname]);
  
  return null;
};

// App component with the proper provider nesting
const App = () => {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthProvider>
            <SubmissionProvider>
              <TooltipProvider>
                <Toaster />
                <Sonner position="top-right" />
                <ScrollToTop />
                <RouteTracker />
                <Routes>
                  <Route path="/" element={<Index />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<Signup />} />
                  <Route 
                    path="/dashboard" 
                    element={
                      <RequireAuth>
                        <Dashboard />
                      </RequireAuth>
                    } 
                  />
                  <Route 
                    path="/admin" 
                    element={
                      <RequireAuth adminOnly>
                        <Admin />
                      </RequireAuth>
                    } 
                  />
                  <Route 
                    path="/upgrade" 
                    element={
                      <RequireAuth>
                        <Upgrade />
                      </RequireAuth>
                    } 
                  />
                  <Route path="/:username" element={<Submission />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </TooltipProvider>
            </SubmissionProvider>
          </AuthProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </React.StrictMode>
  );
};

export default App;
