
import React, { createContext, useContext, ReactNode } from 'react';
import { useSubmissionsData } from '@/hooks/useSubmissions';

// Define the type for the context value to avoid TS errors
type SubmissionContextType = ReturnType<typeof useSubmissionsData>;

// Create a context for the submission data with an initial undefined value
const SubmissionContext = createContext<SubmissionContextType | undefined>(undefined);

// Provider component that wraps parts of the app that need submission data
interface SubmissionProviderProps {
  children: ReactNode;
}

export const SubmissionProvider: React.FC<SubmissionProviderProps> = ({ children }) => {
  try {
    // Initialize the submission data
    const submissionsState = useSubmissionsData();
    
    return (
      <SubmissionContext.Provider value={submissionsState}>
        {children}
      </SubmissionContext.Provider>
    );
  } catch (error) {
    console.error("Error initializing SubmissionProvider:", error);
    // Return children without the context if there's an error
    // This allows the app to at least partially function
    return <>{children}</>;
  }
};

// Hook to use the submission context
export const useSubmissionContext = () => {
  const context = useContext(SubmissionContext);
  if (context === undefined) {
    throw new Error('useSubmissionContext must be used within a SubmissionProvider');
  }
  return context;
};
