
import React from 'react';
import { Link } from 'react-router-dom';
import { CheckCircle, ImageIcon, LockIcon } from 'lucide-react';
import { Separator } from '@/components/ui/separator';
import ImageCard from '@/components/ImageCard';
import { useAuth } from '@/context/AuthContext';
import { useSubmissionContext } from '@/context/SubmissionContext';
import { useIsMobile } from '@/hooks/use-mobile';

// Threshold at which to start showing the limit warning (80% of limit)
const LIMIT_WARNING_THRESHOLD = 0.8;

interface SubmissionGridProps {
  submissions: any[]; // Replace with proper type when available
}

const SubmissionGrid: React.FC<SubmissionGridProps> = ({ submissions }) => {
  const { user } = useAuth();
  const { submissionLimit, viewedSubmissionIds } = useSubmissionContext();
  const isMobile = useIsMobile();
  
  // Only count approved submissions for the limit
  const approvedSubmissions = submissions.filter(s => s.status === 'approved');
  const blurredApprovedCount = approvedSubmissions.length > submissionLimit 
    ? approvedSubmissions.length - submissionLimit 
    : 0;
    
  // Calculate if user is approaching the limit but hasn't reached it yet
  const isApproachingLimit = !user.isPro && 
    approvedSubmissions.length > 0 &&
    (approvedSubmissions.length / submissionLimit) >= LIMIT_WARNING_THRESHOLD && 
    blurredApprovedCount === 0;

  if (submissions.length === 0) {
    return (
      <div className="text-center py-12">
        {submissions[0]?.status === 'approved' ? (
          <>
            <CheckCircle size={48} className="mx-auto text-white/20 mb-4" />
            <h3 className="text-xl font-medium text-white mb-2">No Approved Submissions</h3>
            <p className="text-white/60 mb-6">
              Your approved submissions will appear here.
            </p>
          </>
        ) : (
          <>
            <ImageIcon size={48} className="mx-auto text-white/20 mb-4" />
            <h3 className="text-xl font-medium text-white mb-2">No Pending Submissions</h3>
            <p className="text-white/60 mb-6">
              Share your link to start receiving photos from friends and followers!
            </p>
            <Separator className="my-6 bg-white/10" />
            <p className="text-sm text-white/40">
              All submissions are reviewed by AI for safety
            </p>
          </>
        )}
      </div>
    );
  }

  return (
    <>
      {!user.isPro && blurredApprovedCount > 0 && submissions[0]?.status === 'approved' && (
        <div className="bg-black/40 backdrop-blur-sm border border-white/10 rounded-xl p-4 mb-6 flex items-center">
          <LockIcon size={16} className="text-white/60 mr-3 flex-shrink-0" />
          <p className="text-sm text-white/80">
            <span className="font-medium">{blurredApprovedCount} newest submissions</span> are blurred.{' '}
            <Link to="/upgrade" className="text-white hover:text-white/90 underline font-medium">
              Get Premium
            </Link>{' '}
            to see all your approved submissions.
          </p>
        </div>
      )}
      
      {/* New alert for approaching limit */}
      {isApproachingLimit && submissions[0]?.status === 'approved' && (
        <div className="bg-amber-900/20 border border-amber-500/20 rounded-xl p-4 mb-6 flex items-center">
          <ImageIcon size={16} className="text-amber-400/80 mr-3 flex-shrink-0" />
          <p className="text-sm text-white/80">
            You're approaching the free limit of {submissionLimit} visible photos.{' '}
            <Link to="/upgrade" className="text-amber-400 hover:text-amber-300 underline font-medium">
              Consider Premium
            </Link>{' '}
            for unlimited submissions.
          </p>
        </div>
      )}
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 relative submission-card-grid">
        {submissions.map((submission, index) => (
          <ImageCard 
            key={submission.id} 
            submission={{
              ...submission,
              isOverLimit: submission.isOverLimit || (!user.isPro && 
                submission.status === 'approved' && 
                index < blurredApprovedCount),
              isViewed: submission.isViewed || viewedSubmissionIds.includes(submission.id)
            }}
            isPro={user.isPro}
            index={index}
            isMobile={isMobile}
          />
        ))}
      </div>
    </>
  );
};

export default SubmissionGrid;
