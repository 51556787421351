import React, { useEffect, useState, useRef } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';
import { Loader2 } from 'lucide-react';
import { supabase } from '@/integrations/supabase/client';

interface RequireAuthProps {
  children: React.ReactNode;
  adminOnly?: boolean;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children, adminOnly = false }) => {
  const location = useLocation();
  const [validatingSession, setValidatingSession] = useState(true);
  const [hasValidSession, setHasValidSession] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const profileRefreshedRef = useRef(false);
  
  let authContext;
  let user;
  let isLoading = true;
  let refreshUserProfile;
  
  try {
    authContext = useAuth();
    user = authContext.user;
    isLoading = authContext.isLoading;
    refreshUserProfile = authContext.refreshUserProfile;
  } catch (error) {
    console.error('Auth context not available yet in RequireAuth');
    // If we're on the login page and can't access auth context, just render the children
    if (location.pathname === '/login') {
      return <>{children}</>;
    }
    // Otherwise show loading while waiting for the context to be ready
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader2 className="animate-spin h-8 w-8 text-dropsnap-pink" />
        <span className="ml-2">Loading authentication...</span>
      </div>
    );
  }
  
  // Force check session on component mount 
  useEffect(() => {
    let isMounted = true;
    
    // Skip verification if we know we're logging out
    if (localStorage.getItem('isLoggingOut') === 'true') {
      if (isMounted) {
        setValidatingSession(false);
        setIsCheckingAuth(false);
      }
      return;
    }

    // Verify that we actually have a valid session
    const checkSession = async () => {
      try {
        if (isMounted) setValidatingSession(true);
        const { data } = await supabase.auth.getSession();
        
        if (!isMounted) return;
        
        if (!data.session) {
          console.log('No valid session found');
          setHasValidSession(false);
        } else {
          console.log('Valid session confirmed');
          setHasValidSession(true);
          
          // Only refresh profile once per component mount to prevent infinite loops
          if (refreshUserProfile && user && !profileRefreshedRef.current) {
            try {
              profileRefreshedRef.current = true; // Set this before refreshing to prevent multiple attempts
              await refreshUserProfile();
            } catch (error) {
              console.error('Error refreshing user profile during session check:', error);
            }
          }
        }
      } catch (error) {
        console.error('Error checking session:', error);
        if (isMounted) setHasValidSession(false);
      } finally {
        if (isMounted) {
          setValidatingSession(false);
          setIsCheckingAuth(false);
        }
      }
    };
    
    checkSession();
    
    return () => {
      isMounted = false;
    };
  }, [location.pathname]); // Remove refreshUserProfile and user from the dependency array

  // Wait until we're done loading auth state and validating session
  if (isLoading || validatingSession || isCheckingAuth) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader2 className="animate-spin h-8 w-8 text-dropsnap-pink" />
      </div>
    );
  }

  // Check if user is authenticated and session is valid
  if (!user || !hasValidSession) {
    console.log('User not authenticated or no valid session, redirecting to login');
    // Save the location they were trying to go to
    localStorage.setItem('redirectAfterLogin', location.pathname);
    return <Navigate to="/login" replace />;
  }

  // For admin routes, add additional logging to help debug the issue
  if (adminOnly) {
    console.log('Admin check - user:', user);
    console.log('Admin check - isAdmin property:', user.isAdmin);
    
    if (!user.isAdmin) {
      console.log('Admin access denied, redirecting to dashboard');
      return <Navigate to="/dashboard" replace />;
    } else {
      console.log('Admin access granted');
    }
  }

  // If we're here, user is authenticated and has the required admin status if needed
  return <>{children}</>;
};

export default RequireAuth;
