import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Card, CardContent } from '@/components/ui/card';
import { Loader2 } from 'lucide-react';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { useAuth } from '@/context/AuthContext';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [localLoading, setLocalLoading] = useState(true);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  // Direct approach to get auth context
  let auth;
  try {
    auth = useAuth();
  } catch (error) {
    console.error('Auth context not available yet:', error);
  }

  // Extract needed values or use defaults if auth context is not ready
  const login = auth?.login || (async () => {});
  const user = auth?.user || null;
  const isLoading = auth?.isLoading || false;
  const error = auth?.error || null;

  // Watch for errors from auth context to reset submitting state
  useEffect(() => {
    if (error) {
      setIsSubmitting(false);
    }
  }, [error]);

  useEffect(() => {
    // Clear any logout flags that might be present
    localStorage.removeItem('isLoggingOut');
    
    // Check URL hash for password reset flow
    const hash = window.location.hash;
    
    // Check specifically for recovery type in the hash parameters
    if (hash && hash.includes('type=recovery')) {
      console.log('Recovery flow detected from URL hash');
      setIsResetPassword(true);
      
      // Don't redirect to dashboard if in reset password flow
      return;
    } else if (user && !isResetPassword) {
      // If user is already logged in and not in reset flow, redirect to dashboard
      navigate('/dashboard');
    }
    
    // Set local loading to false after initial check
    setLocalLoading(false);
  }, [user, navigate, isResetPassword]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!login) {
      toast.error('Authentication system is not ready yet. Please try again in a moment.');
      return;
    }
    
    setIsSubmitting(true);
    try {
      // Don't navigate here, let the auth context handle it
      await login(email, password);
      // If login fails, the error effect will reset isSubmitting
    } catch (error) {
      console.error('Login error', error);
      // Make sure to reset isSubmitting on error
      setIsSubmitting(false);
    }
  };

  // Rest of the handlers remain unchanged
  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email || email.trim() === '') {
      toast.error('Please enter your email address');
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      // Get the current URL's origin for the redirect
      const currentOrigin = window.location.origin;
      
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${currentOrigin}/login`,
      });
      
      if (error) {
        throw error;
      }
      
      setResetEmailSent(true);
      toast.success('Password reset link sent to your email');
    } catch (error: any) {
      toast.error(error.message || 'Failed to send reset email');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSetNewPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!newPassword || newPassword.trim() === '') {
      toast.error('Please enter a new password');
      return;
    }
    
    if (newPassword !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }
    
    if (newPassword.length < 6) {
      toast.error('Password must be at least 6 characters');
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      const { error } = await supabase.auth.updateUser({ 
        password: newPassword 
      });
      
      if (error) {
        throw error;
      }
      
      // Clear hash from URL to avoid re-triggering password reset on refresh
      window.history.replaceState({}, document.title, window.location.pathname);
      
      toast.success('Password updated successfully');
      
      // Redirect to dashboard after small delay
      setTimeout(() => {
        navigate('/dashboard');
      }, 1500);
    } catch (error: any) {
      toast.error(error.message || 'Failed to update password');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Show loading state if either local or auth context is loading
  if (isLoading || localLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-[#0a0a0a]">
        <Loader2 className="animate-spin h-8 w-8 text-white" />
      </div>
    );
  }

  // Floating emojis for background
  const floatingEmojis = [
    { emoji: "📸", delay: "0s", position: "top-20 left-[10%]" },
    { emoji: "💌", delay: "0.5s", position: "top-40 right-[15%]" },
    { emoji: "🤫", delay: "1.2s", position: "bottom-32 left-[20%]" },
  ];

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#0a0a0a] p-4 relative overflow-hidden">
      {/* Floating Background Emojis */}
      {floatingEmojis.map((item, index) => (
        <div
          key={index}
          className={`absolute text-6xl md:text-7xl animate-float opacity-20 z-0 ${item.position}`}
          style={{ animationDelay: item.delay }}
        >
          {item.emoji}
        </div>
      ))}

      <div className="w-full max-w-md mx-auto relative z-10">
        <div className="text-center mb-8">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
            Welcome Back
          </h1>
          <p className="text-white/80 text-lg">
            {isResetPassword 
              ? 'Set your new password to continue'
              : isForgotPassword 
                ? 'Enter your email to receive a reset link' 
                : 'Sign in to check your mystery snaps'}
          </p>
        </div>

        <Card className="bg-white/95 backdrop-blur-sm border-0 shadow-2xl">
          <CardContent className="p-6">
            {isResetPassword ? (
              <form onSubmit={handleSetNewPassword} className="space-y-4">
                <div>
                  <Label htmlFor="new-password" className="text-gray-700">New Password</Label>
                  <Input
                    type="password"
                    id="new-password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="mt-1"
                    placeholder="Enter your new password"
                    required
                  />
                </div>
                
                <div>
                  <Label htmlFor="confirm-password" className="text-gray-700">Confirm Password</Label>
                  <Input
                    type="password"
                    id="confirm-password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="mt-1"
                    placeholder="Confirm your new password"
                    required
                  />
                </div>

                <Button 
                  type="submit" 
                  className="w-full bg-[#0a0a0a] hover:bg-black/80 text-white"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <Loader2 className="animate-spin h-4 w-4 mr-2" />
                  ) : null}
                  Set New Password
                </Button>
              </form>
            ) : isForgotPassword ? (
              <>
                {resetEmailSent ? (
                  <div className="text-center py-4">
                    <div className="text-green-500 text-xl mb-4">✓</div>
                    <h3 className="text-xl font-semibold text-gray-800 mb-2">Check Your Email</h3>
                    <p className="text-gray-600 mb-4">
                      We've sent you a link to reset your password.
                    </p>
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => setIsForgotPassword(false)}
                      className="mt-2"
                    >
                      Back to Login
                    </Button>
                  </div>
                ) : (
                  <form onSubmit={handleResetPassword} className="space-y-4">
                    <div>
                      <Label htmlFor="email" className="text-gray-700">Email</Label>
                      <Input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="mt-1"
                        placeholder="Enter your email"
                        required
                      />
                    </div>

                    <Button 
                      type="submit" 
                      className="w-full bg-[#0a0a0a] hover:bg-black/80 text-white"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <Loader2 className="animate-spin h-4 w-4 mr-2" />
                      ) : null}
                      Send Reset Link
                    </Button>

                    <Button
                      type="button"
                      variant="ghost"
                      onClick={() => setIsForgotPassword(false)}
                      className="w-full mt-2"
                    >
                      Back to Login
                    </Button>
                  </form>
                )}
              </>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <Label htmlFor="email" className="text-gray-700">Email</Label>
                  <Input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="mt-1"
                    placeholder="Enter your email"
                    required
                  />
                </div>

                <div>
                  <Label htmlFor="password" className="text-gray-700">Password</Label>
                  <Input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="mt-1"
                    placeholder="Enter your password"
                    required
                  />
                </div>

                <Button 
                  type="submit" 
                  className="w-full bg-[#0a0a0a] hover:bg-black/80 text-white h-11 rounded-xl"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <Loader2 className="animate-spin h-4 w-4 mr-2" />
                  ) : null}
                  Sign In
                </Button>

                <div className="flex items-center justify-between pt-2">
                  <Button
                    type="button"
                    variant="link"
                    onClick={() => setIsForgotPassword(true)}
                    className="text-sm text-gray-600 hover:text-gray-800"
                  >
                    Forgot Password?
                  </Button>
                  <Button
                    type="button"
                    variant="link"
                    onClick={() => navigate('/signup')}
                    className="text-sm text-gray-600 hover:text-gray-800"
                  >
                    Create Account
                  </Button>
                </div>
              </form>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Login;
