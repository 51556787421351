import React, { useState, useEffect } from 'react';
import { useAuth } from '@/context/AuthContext';
import Navbar from '@/components/Navbar';
import { Button } from '@/components/ui/button';
import { CheckCircle, Download, Image as ImageIcon, Clock, Zap, Star, Shield, Crown } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'sonner';
import { supabase } from '@/integrations/supabase/client';
import { Card } from '@/components/ui/card';

const Upgrade = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isVerifying, setIsVerifying] = useState(false);

  // Gumroad product URL
  const gumroadUrl = "https://dropsnap.gumroad.com/l/DropSnapPro";

  // Check for OAuth code in URL
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    if (code && user) {
      verifyGumroadOAuth(code);
    }
  }, [location, user]);

  const handleOpenGumroad = () => {
    window.open(gumroadUrl, '_blank');
  };

  // Verify Gumroad OAuth code
  const verifyGumroadOAuth = async (code: string) => {
    if (!user) {
      toast.error('You must be logged in to verify a purchase');
      return;
    }
    setIsVerifying(true);
    try {
      // Remove code from URL to prevent multiple verification attempts
      navigate('/upgrade', { replace: true });

      const { data, error } = await supabase.functions.invoke('verify-gumroad-license', {
        body: { code, userId: user.id }
      });

      if (error) throw new Error(error.message || 'OAuth verification failed');
      
      if (data?.success) {
        toast.success('Pro access activated successfully!');
        window.location.reload();
      } else {
        toast.error(data?.message || 'Verification failed. Please try again.');
      }
    } catch (error: any) {
      console.error('OAuth verification error:', error);
      toast.error(error.message || 'OAuth verification failed');
    } finally {
      setIsVerifying(false);
    }
  };

  // Check if the pro status is active and not expired
  const isProActive = user?.isPro && (!user.proExpiresAt || new Date(user.proExpiresAt) > new Date());

  const features = [
    {
      icon: <Download className="h-5 w-5" />,
      title: 'High Quality Downloads',
      description: 'Download original, uncompressed images',
    },
    {
      icon: <ImageIcon className="h-5 w-5" />,
      title: 'Unlimited Submissions',
      description: 'No limits on how many photos you can receive',
    },
    {
      icon: <Clock className="h-5 w-5" />,
      title: 'No Auto-Deletion',
      description: 'Keep your photos forever, no 7-day limit',
    },
    {
      icon: <Zap className="h-5 w-5" />,
      title: 'Priority Review',
      description: 'Get your submissions approved faster',
    },
    {
      icon: <Crown className="h-5 w-5" />,
      title: 'Early Access',
      description: 'Be the first to try new features',
    },
    {
      icon: <Shield className="h-5 w-5" />,
      title: 'Premium Support',
      description: 'Get priority assistance when you need help',
    },
  ];

  const faqs = [
    {
      question: "What happens after I upgrade?",
      answer: "You'll instantly get access to all Pro features, including unlimited submissions, high-quality downloads, and priority review. Your account will be upgraded immediately after purchase for 1 week."
    },
    {
      question: "How does billing work?",
      answer: "We use a simple one-time purchase model. Each purchase activates Pro features for 1 week. There's no auto-renewal - you have full control over when to upgrade again."
    },
    {
      question: "Is there a free trial?",
      answer: "We offer free accounts that include up to 25 submissions, allowing you to try the core features before upgrading to Pro for unlimited access."
    },
    {
      question: "What payment methods do you accept?",
      answer: "We accept all major credit cards, PayPal, and Apple Pay through our secure payment processor, Gumroad. Your payment information is never stored on our servers."
    }
  ];

  return (
    <div className="min-h-screen bg-[#0a0a0a] text-white">
      <Navbar />
      
      <div className="pt-24 pb-12 px-4 md:px-8">
        <div className="max-w-4xl mx-auto">
          {/* Header */}
          <div className="text-center mb-12">
            <h1 className="text-4xl md:text-5xl font-bold bg-gradient-to-r from-purple-500 via-pink-500 to-amber-500 bg-clip-text text-transparent mb-4">
              Upgrade to Pro
            </h1>
            <p className="text-lg text-white/80">
              Get unlimited access to all features and unlock your full potential
            </p>
          </div>

          {/* Pro Card */}
          <Card className="dropsnap-card bg-gradient-to-br from-black/80 to-black/40 relative overflow-hidden backdrop-blur-lg border-white/10">
            {/* Popular Badge */}
            <div className="absolute top-4 right-4">
              <div className="bg-gradient-to-r from-purple-500 via-pink-500 to-amber-500 text-white text-xs font-bold px-3 py-1 rounded-full flex items-center gap-1">
                <Star size={12} />
                POPULAR
              </div>
            </div>

            <div className="p-8">
              {/* Price */}
              <div className="mb-8">
                <div className="flex items-baseline justify-center">
                  <span className="text-5xl font-bold text-white">$4.99</span>
                  <span className="text-xl text-white/80 ml-2">/week</span>
                </div>
                <p className="text-center text-white/80 mt-2">
                  One-time purchase • No auto-renewal
                </p>
              </div>

              {/* Features */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                {features.map((feature, index) => (
                  <div key={index} className="flex items-start space-x-3">
                    <div className="mt-1 p-1.5 rounded-full bg-gradient-to-r from-purple-500 via-pink-500 to-amber-500">
                      {feature.icon}
                    </div>
                    <div>
                      <h3 className="font-medium text-white text-base">{feature.title}</h3>
                      <p className="text-sm text-white/80">{feature.description}</p>
                    </div>
                  </div>
                ))}
              </div>

              {/* CTA Button */}
              <Button 
                className="w-full dropsnap-button text-lg h-12 font-semibold"
                onClick={handleOpenGumroad}
                disabled={isVerifying}
              >
                {isVerifying ? 'Verifying...' : isProActive ? 'Purchase Another Week' : 'Upgrade Now'}
              </Button>
            </div>
          </Card>

          {/* FAQ Section */}
          <div className="mt-16">
            <h2 className="text-2xl font-bold text-white text-center mb-8">
              Frequently Asked Questions
            </h2>
            <div className="grid gap-6">
              {faqs.map((faq, index) => (
                <div key={index} className="dropsnap-card bg-black/60">
                  <h3 className="font-semibold text-white mb-2">
                    {faq.question}
                  </h3>
                  <p className="text-white/80">
                    {faq.answer}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upgrade;