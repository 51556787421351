import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { User, Session } from '@supabase/supabase-js';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import { 
  fetchUserProfile, 
  loginUser, 
  signupUser, 
  logoutUser, 
  checkUsernameAvailability, 
  upgradeUserAccount 
} from '@/services/auth';

// Define the shape of the User object with our custom fields
export interface AppUser extends User {
  username?: string;
  isPro?: boolean;
  isAdmin?: boolean;
  proExpiresAt?: string | null;
}

// Define the shape of our authentication context
interface AuthContextType {
  user: AppUser | null;
  session: Session | null;
  isLoading: boolean;
  error: string | null;
  login: (email: string, password: string) => Promise<void>;
  signup: (username: string, email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  checkUsernameAvailability: (username: string) => Promise<boolean>;
  upgradeUserAccount: () => Promise<void>;
  refreshUserProfile: () => Promise<void>;
}

// Create the context with default values
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Provider component that wraps the app and makes auth available to any child component
export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<AppUser | null>(null);
  const [session, setSession] = useState<Session | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [authInitialized, setAuthInitialized] = useState<boolean>(false);

  // Add a refresh function to manually refresh the profile
  const refreshUserProfile = async () => {
    if (!session?.user) return;
    
    try {
      setIsLoading(true);
      const { data: userProfile, error: profileError } = await fetchUserProfile(session.user);
      
      if (profileError) {
        console.error("Error refreshing user profile:", profileError);
        return;
      }
      
      if (userProfile) {
        console.log("User profile refreshed:", userProfile);
        console.log("Admin status refreshed:", userProfile.isAdmin);
        setUser(userProfile);
      }
    } catch (err) {
      console.error("Error in profile refresh:", err);
    } finally {
      setIsLoading(false);
    }
  };

  // Check for an existing session on component mount
  useEffect(() => {
    let mounted = true;
    
    // Set up auth state listener FIRST
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      async (event, newSession) => {
        console.log('Auth state changed:', event, !!newSession);
        
        if (!mounted) return;
        
        setSession(newSession);
        
        if (newSession?.user) {
          try {
            // Use setTimeout to prevent potential deadlocks
            setTimeout(async () => {
              if (!mounted) return;
              
              // Fetch the latest profile data
              const { data: userProfile, error: profileError } = await fetchUserProfile(newSession.user);
              
              if (profileError && mounted) {
                console.error("Error fetching user profile during auth state change:", profileError);
              }
              
              if (userProfile && mounted) {
                console.log("User profile loaded:", userProfile);
                console.log("Admin status:", userProfile.isAdmin);
                setUser(userProfile);
                setIsLoading(false);
              }
            }, 0);
          } catch (err) {
            console.error("Error in profile fetch during auth state change:", err);
            if (mounted) {
              setUser({
                ...newSession.user,
                username: newSession.user.user_metadata?.username || newSession.user.email?.split('@')[0],
                isPro: false,
                isAdmin: false,
              });
              setIsLoading(false);
            }
          }
        } else {
          if (mounted) {
            setUser(null);
            setIsLoading(false);
          }
        }
      }
    );

    // THEN check for existing session
    const getSession = async () => {
      try {
        const { data, error } = await supabase.auth.getSession();
        
        if (!mounted) return;
        
        if (error) {
          throw error;
        }
        
        setSession(data.session);
        
        if (data.session?.user) {
          // Fetch additional profile data
          setTimeout(async () => {
            if (!mounted) return;
            
            try {
              const { data: userProfile, error: profileError } = await fetchUserProfile(data.session.user);
              if (profileError) {
                console.error("Error fetching user profile:", profileError);
              }
              
              if (userProfile && mounted) {
                console.log("Initial user profile loaded:", userProfile);
                console.log("Admin status:", userProfile.isAdmin);
                setUser(userProfile);
              }
            } catch (profileErr) {
              console.error("Error in profile fetch:", profileErr);
              if (mounted) {
                setUser({
                  ...data.session.user,
                  username: data.session.user.user_metadata?.username || data.session.user.email?.split('@')[0],
                  isPro: false,
                  isAdmin: false,
                });
              }
            }
          }, 0);
        } else {
          setUser(null);
        }
      } catch (err) {
        console.error("Session retrieval error:", err);
        if (mounted) setUser(null);
      } finally {
        if (mounted) {
          setIsLoading(false);
          setAuthInitialized(true);
        }
      }
    };

    getSession();

    return () => {
      mounted = false;
      subscription.unsubscribe();
    };
  }, []);

  // Login function
  const login = async (email: string, password: string) => {
    try {
      setError(null);
      setIsLoading(true);
      
      const { data, error } = await loginUser(email, password);
      
      if (error) {
        throw new Error(error);
      }
      
      // The auth listener will handle updating state
      
      // Always redirect to dashboard after successful login
      navigate('/dashboard');
    } catch (error: any) {
      console.error('Login error:', error.message);
      setError(error.message);
      toast.error(error.message || 'Failed to log in');
      throw error; // Re-throw so the Login component can handle it
    } finally {
      setIsLoading(false);
    }
  };

  // Signup function
  const signup = async (username: string, email: string, password: string) => {
    try {
      setError(null);
      setIsLoading(true);
      
      const { data, error } = await signupUser(username, email, password);
      
      if (error) {
        throw new Error(error);
      }
      
      // If signup successful, let the auth listener handle updating state
      toast.success('Account created successfully!');
      
      // Redirect to dashboard
      navigate('/dashboard');
    } catch (error: any) {
      console.error('Signup error:', error.message);
      setError(error.message);
      toast.error(error.message || 'Failed to create account');
    } finally {
      setIsLoading(false);
    }
  };

  // Logout function
  const logout = async () => {
    try {
      setIsLoading(true);
      localStorage.setItem('isLoggingOut', 'true');
      
      const { error } = await logoutUser();
      
      // Clear state regardless of whether the API call was successful
      // This ensures the user is logged out on the client side
      setUser(null);
      setSession(null);
      
      // Redirect to home page
      navigate('/');
      
      // Clear logout flag
      localStorage.removeItem('isLoggingOut');
      
      if (error) {
        console.error('Logout error:', error);
        // We don't show an error toast here as we've already logged the user out locally
      }
    } catch (error: any) {
      console.error('Logout error:', error.message);
      // Even if there's an error, we'll still clear the local state and redirect
      setUser(null);
      setSession(null);
      navigate('/');
      localStorage.removeItem('isLoggingOut');
    } finally {
      setIsLoading(false);
    }
  };

  // Check username availability
  const checkUsername = async (username: string): Promise<boolean> => {
    try {
      return await checkUsernameAvailability(username);
    } catch (error: any) {
      console.error('Username check error:', error.message);
      throw error;
    }
  };

  // Upgrade user account to Pro
  const upgradeUser = async () => {
    try {
      setError(null);
      setIsLoading(true);
      
      if (!user) {
        throw new Error('You need to be logged in to upgrade your account');
      }
      
      const { error } = await upgradeUserAccount(user.id);
      
      if (error) {
        throw new Error(error);
      }
      
      // Update the local user state
      setUser({
        ...user,
        isPro: true,
        proExpiresAt: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(),
      });
      
      toast.success('Your account has been upgraded to Pro!');
    } catch (error: any) {
      console.error('Upgrade error:', error.message);
      setError(error.message);
      toast.error(error.message || 'Failed to upgrade account');
    } finally {
      setIsLoading(false);
    }
  };

  // Provide the auth context to children components
  return (
    <AuthContext.Provider
      value={{
        user,
        session,
        isLoading,
        error,
        login,
        signup,
        logout,
        checkUsernameAvailability: checkUsername,
        upgradeUserAccount: upgradeUser,
        refreshUserProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Hook to use auth context in components
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
