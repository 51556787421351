
import React from 'react';
import { useAuth } from '@/context/AuthContext';
import ShareSteps from './ShareSteps';

interface ShareLinkSectionProps {
  className?: string;
}

const ShareLinkSection: React.FC<ShareLinkSectionProps> = ({
  className = ''
}) => {
  const { user } = useAuth();
  
  // Always use dropsnap.link for production links
  const domain = 'dropsnap.link';
  const link = `https://${domain}/${user?.username}`;

  if (!user) return null;

  return (
    <div className={className}>
      <ShareSteps link={link} />
    </div>
  );
};

export default ShareLinkSection;
