
import React, { useState, useEffect } from 'react';
import { ImageSubmission } from '@/types';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Clock, Lock, AlertTriangle, Eye } from 'lucide-react';
import { toast } from 'sonner';
import { useSubmissionContext } from '@/context/SubmissionContext';
import ImagePopup from './ImagePopup';
import { FREE_TIER_SUBMISSION_LIMIT } from '@/services/submissionService';
import { useAuth } from '@/context/AuthContext';

interface ImageCardProps {
  submission: ImageSubmission;
  isAdmin?: boolean;
  onApprove?: (id: string) => void;
  onReject?: (id: string) => void;
  isPro?: boolean;
  index?: number;
  isMobile?: boolean;
}

const ImageCard: React.FC<ImageCardProps> = ({
  submission,
  isAdmin = false,
  onApprove,
  onReject,
  isPro = false,
  index = 0,
  isMobile = false,
}) => {
  const { user } = useAuth();
  const isOverLimit = submission.isOverLimit === true;
  const isExpired = submission.isExpired === true;
  const [isViewed, setIsViewed] = useState(submission.isViewed || false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { generateShareableImage, markSubmissionViewed } = useSubmissionContext();

  // Update local state when the prop changes
  useEffect(() => {
    setIsViewed(submission.isViewed || false);
  }, [submission.isViewed]);

  const openPopup = async () => {
    // Don't open popup for rejected photos
    if (submission.status === 'rejected' && !isAdmin) {
      toast.info('This image was rejected by AI due to inappropriate content.');
      return;
    }
    
    if (isOverLimit) {
      toast.info(`Upgrade to Pro to see all submissions beyond the ${FREE_TIER_SUBMISSION_LIMIT} limit`);
      return;
    }
    
    if (isExpired) {
      toast.info('This image has expired. Pro users can keep images indefinitely.');
      return;
    }

    if (submission.status === 'pending' && !isAdmin) {
      toast.info('This image is pending AI review.');
      return;
    }
    
    // Mark image as viewed in the database if it's approved and the user is logged in
    if (!isViewed && submission.status === 'approved' && user) {
      try {
        console.log('Marking submission as viewed from ImageCard:', submission.id);
        await markSubmissionViewed(submission.id);
        setIsViewed(true);
      } catch (error) {
        console.error('Error marking image as viewed:', error);
      }
    }
    
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  // Format date and time
  const formatDateTime = (date: Date) => {
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString(undefined, { 
      hour: '2-digit', 
      minute: '2-digit'
    });
    
    return `${formattedDate} ${formattedTime}`;
  };

  // Display status with custom text for rejected items
  const getStatusDisplay = () => {
    if (isExpired) return "Expired";
    if (submission.status === 'rejected') return "Rejected by AI (inappropriate content)";
    return submission.status;
  };

  // Check if the image should be blurred
  const shouldBlurImage = () => {
    // Blur if:
    // 1. It's not viewed AND it's approved AND user isn't admin, OR
    // 2. It's pending AND user isn't admin, OR
    // 3. It's rejected AND user isn't admin
    return (!isViewed && submission.status === 'approved' && !isAdmin) || 
           (submission.status === 'pending' && !isAdmin) ||
           (submission.status === 'rejected' && !isAdmin);
  };

  return (
    <>
      <Card 
        className="relative overflow-hidden transition-all duration-300 transform hover:shadow-lg cursor-pointer force-transparent card-item"
        onClick={openPopup}
        data-index={index}
      >
        <CardContent className="p-0 relative">
          <div className="relative">
            {isExpired ? (
              <div className="w-full h-64 bg-gray-100 flex flex-col items-center justify-center">
                <AlertTriangle size={40} className="text-gray-400 mb-2" />
                <p className="text-gray-500 font-medium">Photo Expired</p>
                <p className="text-xs text-gray-400 mt-1">
                  {!isPro && "Upgrade to Pro to keep photos indefinitely"}
                </p>
              </div>
            ) : (
              <>
                <img
                  src={submission.imageUrl}
                  alt="Submission"
                  className={`w-full h-64 object-contain bg-gray-50 ${
                    shouldBlurImage() ? 'blur-lg' : ''
                  } transition-all duration-300`}
                />
                {shouldBlurImage() && !isOverLimit && !isExpired && (
                  <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/50 text-white">
                    <p className="text-2xl font-medium text-center px-4">
                      {submission.status === 'pending' ? 'Pending AI Review' : 
                       submission.status === 'rejected' ? 'Rejected by AI' : 'Tap to see'}
                    </p>
                    {submission.status === 'rejected' && (
                      <p className="text-sm mt-2 text-center px-4 text-white/80">
                        Inappropriate content detected
                      </p>
                    )}
                  </div>
                )}
                {isViewed && submission.status === 'approved' && !isAdmin && !isOverLimit && !isExpired && (
                  <div className="absolute top-2 right-2 bg-black/70 p-1 rounded">
                    <Eye size={18} className="text-white opacity-70" />
                  </div>
                )}
              </>
            )}
            
            {isOverLimit && !isExpired && (
              <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/50 text-white">
                <Lock size={28} className="mb-2" />
                <p className="text-sm font-medium text-center px-4">
                  Upgrade to Pro to unlock all submissions
                </p>
              </div>
            )}
          </div>
          
          {isAdmin && !isExpired && submission.status !== 'rejected' && (
            <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-4">
              <Button
                variant="destructive"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  onReject && onReject(submission.id);
                }}
                className="px-4 shadow-lg"
              >
                Reject
              </Button>
              <Button
                variant="default"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  onApprove && onApprove(submission.id);
                }}
                className="bg-green-500 hover:bg-green-600 px-4 shadow-lg"
              >
                Approve
              </Button>
            </div>
          )}
          
          <div className="p-4 bg-transparent backdrop-blur-lg">
            {submission.caption && (
              <p className="text-sm text-white mb-2">
                {submission.caption}
              </p>
            )}
            
            <div className="flex justify-between items-center">
              <p className="text-sm text-gray-500">
                {formatDateTime(new Date(submission.createdAt))}
              </p>
              
              {!isPro && submission.expiresAt && !isExpired && (
                <div className="flex items-center text-xs text-amber-600">
                  <Clock size={12} className="mr-1" />
                  {new Date(submission.expiresAt).getTime() - new Date().getTime() <= 86400000
                    ? "Expires today" 
                    : `${Math.ceil((new Date(submission.expiresAt).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))}d left`}
                </div>
              )}
              
              {isExpired && (
                <div className="flex items-center text-xs text-red-500">
                  <Clock size={12} className="mr-1" />
                  Expired
                </div>
              )}
            </div>
            
            <div className="flex items-center mt-1">
              <div className={`w-2 h-2 rounded-full mr-2 ${
                isExpired 
                  ? 'bg-gray-400'
                  : submission.status === 'approved' 
                  ? 'bg-green-500' 
                  : submission.status === 'pending' 
                  ? 'bg-yellow-500' 
                  : 'bg-red-500'
              }`}></div>
              <p className="text-sm text-white capitalize">
                {getStatusDisplay()}
              </p>
              
              {isOverLimit && !isExpired && (
                <span className="ml-2 text-xs bg-black/30 text-white px-1.5 py-0.5 rounded">
                  Pro Only
                </span>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
      
      {/* Only render the ImagePopup if the submission isn't rejected or if the user is an admin */}
      {(submission.status !== 'rejected' || isAdmin) && (
        <ImagePopup 
          isOpen={isPopupOpen}
          onClose={closePopup}
          submission={isPopupOpen ? submission : null}
          isPro={isPro}
          generateShareableImage={generateShareableImage}
        />
      )}
    </>
  );
};

export default ImageCard;
