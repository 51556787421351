import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { ArrowDown, Camera, Shield, Share2, ArrowRight, CheckCircle, Users } from 'lucide-react';
import Navbar from '@/components/Navbar';
import { useAuth } from '@/context/AuthContext';
import { trackEvent } from '@/hooks/useAnalytics';
const Index = () => {
  const {
    user
  } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      console.log('User is logged in, redirecting to dashboard');
    }
  }, [user]);
  const handleCreateLinkClick = (e: React.MouseEvent) => {
    trackEvent('cta_clicked', 'homepage', 'create_link');
    if (user) {
      e.preventDefault();
      navigate('/dashboard');
    }
  };

  // Floating emojis with different animation delays
  const floatingEmojis = [{
    emoji: "📸",
    delay: "0s",
    position: "top-20 left-[10%]"
  }, {
    emoji: "💌",
    delay: "0.5s",
    position: "top-40 right-[15%]"
  }, {
    emoji: "🤫",
    delay: "1s",
    position: "bottom-32 left-[20%]"
  }, {
    emoji: "👗",
    delay: "1.5s",
    position: "top-60 right-[25%]"
  }, {
    emoji: "💭",
    delay: "2s",
    position: "bottom-40 right-[10%]"
  }];

  // Testimonials data
  const testimonials = [{
    name: "Priya K.",
    text: "Got 50+ photos from friends I hadn't seen in years. Totally worth it!"
  }, {
    name: "Mark T.",
    text: "My followers sent me the funniest throwbacks. Can't believe what people had saved!"
  }, {
    name: "Sarah J.",
    text: "Used this for my birthday and got the best photo memories from everyone."
  }];
  return <div className="min-h-screen bg-[#0a0a0a] text-white overflow-hidden">
      <Navbar />
      
      {/* Floating Emojis */}
      {floatingEmojis.map((item, index) => <div key={index} className={`absolute text-6xl md:text-7xl animate-float opacity-20 z-0 ${item.position}`} style={{
      animationDelay: item.delay
    }}>
          {item.emoji}
        </div>)}
      
      {/* Hero Section */}
      <section className="pt-24 pb-12 px-4 md:px-6 relative">
        <div className="max-w-6xl mx-auto relative z-10">
          <div className="text-center">
            <h1 className="text-4xl font-bold mb-4">Share Anonymous Stories</h1>
            <p className="text-lg mb-8">Join 1500+ people sharing anonymous stories every day!</p>
            
            <div className="flex items-center justify-center gap-2 mb-8">
              <Users className="h-5 w-5 text-pink-400" />
              <span className="text-pink-400 font-medium">1500+ people signed up in the last week</span>
            </div>
            
            <div className="flex flex-col items-center gap-4 mt-8">
              <Link to={user ? "/dashboard" : "/signup"} onClick={handleCreateLinkClick}>
                <Button className="bg-white text-[#0a0a0a] hover:bg-white/90 text-lg h-14 px-8 rounded-full animate-pulse-light">
                  {user ? "Go to Dashboard" : "Get Your Free DropSnap Link"} <ArrowRight size={20} className="ml-2" />
                </Button>
              </Link>
              <p className="text-white/70 text-sm mt-2">Free setup • Takes only 15 seconds</p>
            </div>

            <div className="scroll-indicator mt-16">
              <ArrowDown size={16} />
              <span>how it works?</span>
              <ArrowDown size={16} />
            </div>
          </div>
        </div>
      </section>

      {/* How It Works Section - NEW */}
      <section className="py-16 md:py-24 px-4 md:px-6 text-center relative bg-black/40">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl md:text-5xl font-bold mb-12 bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-500">
            How DropSnap Works
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10 text-center">
            <div className="flex flex-col items-center">
              <div className="w-16 h-16 rounded-full bg-purple-500/20 flex items-center justify-center mb-4">
                <div className="text-2xl">1</div>
              </div>
              <h3 className="text-xl font-bold mb-2">Create Your Link</h3>
              <p className="text-white/80">
                Sign up in seconds and get your unique DropSnap link to share
              </p>
            </div>
            
            <div className="flex flex-col items-center">
              <div className="w-16 h-16 rounded-full bg-pink-500/20 flex items-center justify-center mb-4">
                <div className="text-2xl">2</div>
              </div>
              <h3 className="text-xl font-bold mb-2">Share With Friends</h3>
              <p className="text-white/80">
                Post your link on social media or send it directly to friends
              </p>
            </div>
            
            <div className="flex flex-col items-center">
              <div className="w-16 h-16 rounded-full bg-amber-500/20 flex items-center justify-center mb-4">
                <div className="text-2xl">3</div>
              </div>
              <h3 className="text-xl font-bold mb-2">Receive Anonymous Photos</h3>
              <p className="text-white/80">
                Watch as mystery photos from your past appear in your inbox
              </p>
            </div>
          </div>
          
          <div className="mt-12">
            <Link to="/signup">
              <Button className="bg-gradient-to-r from-purple-500 via-pink-500 to-amber-500 text-white hover:opacity-90 text-lg px-8 h-12 rounded-full">
                Create Your Link Now
              </Button>
            </Link>
          </div>
        </div>
      </section>

      {/* What You Might Get Section */}
      <section className="py-16 md:py-24 px-4 md:px-6 text-center relative">
        <h2 className="text-3xl md:text-5xl font-bold mb-8 md:mb-12">
          Someone might drop...
        </h2>

        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-6 max-w-5xl mx-auto">
          <div className="feature-card group">
            <div className="preview-card from-pink-400 to-purple-500 relative">
              <div className="absolute inset-0 bg-black/40 backdrop-blur-sm"></div>
              <span className="text-3xl md:text-5xl relative z-10">📸</span>
            </div>
            <p className="text-white text-sm md:text-lg mt-3 md:mt-4 font-medium">
              That night you don't remember
            </p>
          </div>

          <div className="feature-card group">
            <div className="preview-card from-blue-400 to-purple-500 relative">
              <div className="absolute inset-0 bg-black/40 backdrop-blur-sm"></div>
              <span className="text-3xl md:text-5xl relative z-10">👕</span>
            </div>
            <p className="text-white text-sm md:text-lg mt-3 md:mt-4 font-medium">
              A fashion fit you'd slay in
            </p>
          </div>

          <div className="feature-card group">
            <div className="preview-card from-purple-400 to-pink-500 relative">
              <div className="absolute inset-0 bg-black/40 backdrop-blur-sm"></div>
              <span className="text-3xl md:text-5xl relative z-10">💌</span>
            </div>
            <p className="text-white text-sm md:text-lg mt-3 md:mt-4 font-medium">
              Screenshots you should've seen
            </p>
          </div>

          <div className="feature-card group">
            <div className="preview-card from-indigo-400 to-purple-500 relative">
              <div className="absolute inset-0 bg-black/40 backdrop-blur-sm"></div>
              <span className="text-3xl md:text-5xl relative z-10">🧠</span>
            </div>
            <p className="text-white text-sm md:text-lg mt-3 md:mt-4 font-medium">
              Wild memories you forgot about
            </p>
          </div>

          <div className="feature-card group">
            <div className="preview-card from-purple-400 to-blue-500 relative">
              <div className="absolute inset-0 bg-black/40 backdrop-blur-sm"></div>
              <span className="text-3xl md:text-5xl relative z-10">📍</span>
            </div>
            <p className="text-white text-sm md:text-lg mt-3 md:mt-4 font-medium">
              Places you've been — or should go
            </p>
          </div>

          <div className="feature-card group">
            <div className="preview-card from-pink-400 to-indigo-500 relative">
              <div className="absolute inset-0 bg-black/40 backdrop-blur-sm"></div>
              <span className="text-3xl md:text-5xl relative z-10">🤫</span>
            </div>
            <p className="text-white text-sm md:text-lg mt-3 md:mt-4 font-medium">
              Proof they still think about you
            </p>
          </div>
        </div>
      </section>

      {/* Testimonials Section - NEW */}
      <section className="py-16 md:py-24 px-4 md:px-6 text-center relative bg-black/40">
        <div className="max-w-5xl mx-auto">
          <h2 className="text-3xl md:text-5xl font-bold mb-12">
            What People Are Saying
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {testimonials.map((testimonial, index) => <div key={index} className="bg-white/5 backdrop-blur-sm p-6 rounded-xl border border-white/10">
                <div className="flex items-center justify-center mb-4">
                  {[...Array(5)].map((_, i) => <svg key={i} className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                    </svg>)}
                </div>
                <p className="text-white/90 italic mb-4">"{testimonial.text}"</p>
                <p className="text-white/60 text-sm">{testimonial.name}</p>
              </div>)}
          </div>
          
          <div className="mt-12">
            <Link to="/signup">
              <Button className="bg-white text-[#0a0a0a] hover:bg-white/90 text-lg h-12 px-8 rounded-full">
                Join Them Now
              </Button>
            </Link>
          </div>
        </div>
      </section>

      {/* Live Feed Section */}
      <section className="py-16 md:py-24 px-4 md:px-6 text-center relative">
        <h2 className="text-4xl md:text-5xl font-bold mb-6">
          This could be your inbox...
        </h2>
        <p className="text-white/80 text-lg mb-12 max-w-2xl mx-auto">
          Get ready for surprise snaps from your friends
        </p>

        <div className="max-w-lg mx-auto space-y-4">
          <div className="animate-slide-up">
            <div className="feed-card">
              <div className="preview-card-small from-blue-400 to-purple-500">
                <span className="text-3xl">📸</span>
              </div>
              <p className="text-gray-800 text-lg text-left flex-1">
                "You need to bring back that 2018 hairstyle 💇‍♂️"
              </p>
            </div>
          </div>

          <div className="animate-slide-up" style={{
          animationDelay: '0.2s'
        }}>
            <div className="feed-card">
              <div className="preview-card-small from-purple-400 to-pink-500">
                <span className="text-3xl">🌊</span>
              </div>
              <p className="text-gray-800 text-lg text-left flex-1">
                "Remember Goa, 2019? I have the receipts 📸"
              </p>
            </div>
          </div>

          <div className="animate-slide-up" style={{
          animationDelay: '0.4s'
        }}>
            <div className="feed-card">
              <div className="preview-card-small from-pink-400 to-indigo-500">
                <span className="text-3xl">👗</span>
              </div>
              <p className="text-gray-800 text-lg text-left flex-1">
                "This outfit screams YOU 👗"
              </p>
            </div>
          </div>
        </div>
        
        <div className="mt-12">
          <Link to="/signup">
            <Button className="bg-gradient-to-r from-purple-500 to-pink-500 text-white hover:opacity-90 text-lg px-8 h-12 rounded-full">
              Create Your Free DropSnap <ArrowRight size={20} className="ml-2" />
            </Button>
          </Link>
          <p className="text-white/60 text-sm mt-3">
            No credit card needed • Takes 30 seconds to set up
          </p>
        </div>
      </section>

      {/* FAQ Section - NEW */}
      <section className="py-16 md:py-24 px-4 md:px-6 text-center relative bg-black/40">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl md:text-5xl font-bold mb-12">
            Frequently Asked Questions
          </h2>
          
          <div className="space-y-6 text-left">
            <div className="bg-white/5 backdrop-blur-sm p-6 rounded-xl border border-white/10">
              <h3 className="text-xl font-bold mb-2">Is it really anonymous?</h3>
              <p className="text-white/80">
                Yes! We don't collect or share any information about who submits photos to your DropSnap.
              </p>
            </div>
            
            
            
            <div className="bg-white/5 backdrop-blur-sm p-6 rounded-xl border border-white/10">
              <h3 className="text-xl font-bold mb-2">Is DropSnap really free?</h3>
              <p className="text-white/80">
                Yes! DropSnap is completely free to use with basic features. We also offer a Pro plan with additional capabilities.
              </p>
            </div>
          </div>
          
          <div className="mt-12">
            <Link to="/signup">
              <Button className="bg-white text-[#0a0a0a] hover:bg-white/90 text-lg h-12 px-8 rounded-full">
                Get Started For Free
              </Button>
            </Link>
          </div>
        </div>
      </section>

      {/* Final CTA Section - NEW */}
      <section className="py-16 px-4 md:px-6 text-center bg-gradient-to-r from-purple-500/20 via-pink-500/20 to-amber-500/20 relative">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-4xl md:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-500 via-pink-500 to-amber-500">
            Ready for some nostalgia?
          </h2>
          
          <p className="text-xl md:text-2xl text-white/90 mb-10">
            Create your DropSnap link now and start collecting memories
          </p>
          
          <div className="space-y-4 md:space-y-0 md:flex md:justify-center md:gap-4 py-0">
            <Link to="/signup">
              <Button className="w-full md:w-auto bg-white text-[#0a0a0a] hover:bg-white/90 text-lg h-14 px-10 rounded-full py-0 my-[14px]">
                Get Started (Free)
              </Button>
            </Link>
            
            <Link to="/login">
              <Button variant="outline" className="w-full md:w-auto border-white/30 text-white text-lg h-14 px-10 rounded-full bg-slate-950 hover:bg-slate-800 py-0">
                Log In
              </Button>
            </Link>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="py-8 px-4 md:px-6 text-white/80">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0 text-center md:text-left">
            <span className="text-xl font-bold">
              DropSnap
            </span>
            <p className="text-sm mt-1">© 2025 DropSnap. All rights reserved.</p>
          </div>
          
          <div className="flex flex-wrap justify-center md:justify-end gap-6">
            <Link to="/privacy" className="text-sm hover:text-white">
              Privacy Policy
            </Link>
            <Link to="/terms" className="text-sm hover:text-white">
              Terms of Service
            </Link>
            <Link to="/contact" className="text-sm hover:text-white">
              Contact Us
            </Link>
          </div>
        </div>
      </footer>
    </div>;
};
export default Index;
