
import { v4 as uuidv4 } from 'uuid';
import { ImageSubmission } from '@/types';
import { supabase } from '@/integrations/supabase/client';

export const fetchUserSubmissions = async (userId: string): Promise<ImageSubmission[]> => {
  try {
    // Use the RPC function to avoid RLS recursion issues
    const { data, error } = await supabase
      .rpc('get_user_submissions', { user_id_param: userId });
    
    if (error) {
      throw error;
    }
    
    if (!data || !Array.isArray(data)) {
      return [];
    }
    
    // Transform the data to match our ImageSubmission type
    const submissions: ImageSubmission[] = data.map((item: any) => ({
      id: item.id,
      userId: item.user_id,
      imageUrl: item.image_url,
      blurredImageUrl: item.blurred_image_url || item.image_url,
      status: item.status as 'pending' | 'approved' | 'rejected',
      caption: item.caption,
      createdAt: new Date(item.created_at),
      expiresAt: item.expires_at ? new Date(item.expires_at) : undefined
    }));
    
    return submissions;
  } catch (error) {
    throw error;
  }
};

// Function to fetch all pending submissions (for admin use)
export const fetchAllPendingSubmissions = async (): Promise<ImageSubmission[]> => {
  try {
    // Use our new RPC function that bypasses RLS for admins
    const { data, error } = await supabase
      .rpc('get_all_pending_submissions');
    
    if (error) {
      throw error;
    }
    
    if (!data || !Array.isArray(data)) {
      return [];
    }
    
    // Transform the data to match our ImageSubmission type
    const submissions: ImageSubmission[] = data.map((item: any) => ({
      id: item.id,
      userId: item.user_id,
      imageUrl: item.image_url,
      blurredImageUrl: item.blurred_image_url || item.image_url,
      status: item.status as 'pending' | 'approved' | 'rejected',
      caption: item.caption,
      createdAt: new Date(item.created_at),
      expiresAt: item.expires_at ? new Date(item.expires_at) : undefined
    }));
    
    return submissions;
  } catch (error) {
    throw error;
  }
};

export const uploadImageToStorage = async (userId: string, imageFile: File): Promise<string> => {
  try {
    const filePath = `${userId}/${uuidv4()}-${imageFile.name.replace(/[^a-zA-Z0-9.-]/g, '')}`;
    
    const { data, error } = await supabase
      .storage
      .from('images')
      .upload(filePath, imageFile, {
        cacheControl: '3600',
        upsert: false,
      });
    
    if (error) {
      throw error;
    }
    
    // Get the public URL for the uploaded image
    const { data: { publicUrl } } = supabase
      .storage
      .from('images')
      .getPublicUrl(data.path);
    
    return publicUrl;
  } catch (error) {
    throw error;
  }
};

export const createSubmission = async (userId: string, imageUrl: string, caption?: string): Promise<any> => {
  try {
    // For now, blur URL is the same as original URL
    // In a real app, we would generate a blurred version
    const blurredImageUrl = imageUrl;
    
    // Use the RPC function to create the submission
    // We need to cast the parameters to any to handle the caption_param
    const { data, error } = await supabase
      .rpc('insert_submission', { 
        user_id_param: userId, 
        image_url_param: imageUrl, 
        blurred_url_param: blurredImageUrl,
        caption_param: caption
      } as any); // Use type assertion to avoid TypeScript error
    
    if (error) {
      throw error;
    }
    
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateSubmissionStatus = async (submissionId: string, status: 'approved' | 'rejected'): Promise<void> => {
  try {
    // Use the RPC function to update the submission status
    const { error } = await supabase
      .rpc('update_submission_status', { 
        submission_id_param: submissionId, 
        status_param: status 
      });
    
    if (error) {
      throw error;
    }
    
    // If rejecting, attempt to delete the file from storage
    if (status === 'rejected') {
      try {
        // First get the submission to get the image URL
        const { data: submission } = await supabase
          .from('submissions')
          .select('image_url')
          .eq('id', submissionId)
          .single();
          
        if (submission && submission.image_url) {
          // Extract the path from the URL
          const urlPath = new URL(submission.image_url).pathname;
          const pathParts = urlPath.split('/');
          const bucketName = 'images';
          
          // Find the storage path (everything after /storage/v1/object/public/images/)
          const bucketIndex = pathParts.findIndex(part => part === bucketName);
          if (bucketIndex !== -1) {
            const storagePath = pathParts.slice(bucketIndex + 1).join('/');
            
            if (storagePath) {
              // Delete from storage
              await supabase.storage
                .from(bucketName)
                .remove([storagePath]);
            }
          }
        }
      } catch (deleteError) {
        // We don't throw here as the status was already updated
      }
    }
  } catch (error) {
    throw error;
  }
};

// Function to run delete_expired_submissions database function
export const cleanupExpiredSubmissions = async (): Promise<void> => {
  try {
    const { error } = await supabase.rpc('delete_expired_submissions');
    
    if (error) {
      throw error;
    }
  } catch (error) {
    throw error;
  }
};

// The free tier submission limit
export const FREE_TIER_SUBMISSION_LIMIT = 25;

// Get the count of approved submissions for a user
export const getUserSubmissionCount = async (userId: string): Promise<number> => {
  try {
    // Get all submissions and filter for approved ones
    const submissions = await fetchUserSubmissions(userId);
    const approvedCount = submissions.filter(s => s.status === 'approved').length;
    
    return approvedCount;
  } catch (error: any) {
    throw new Error(error.message || 'Error getting submission count');
  }
};

// Check if a user has reached their submission limit
// With the modified behavior, this just determines if new approved submissions will be blurred
// but doesn't prevent submissions
export const hasReachedSubmissionLimit = async (userId: string, isPro: boolean): Promise<boolean> => {
  try {
    // Pro users have unlimited visible submissions
    if (isPro) return false;
    
    // For free users, check only approved submissions count
    const count = await getUserSubmissionCount(userId);
    return count >= FREE_TIER_SUBMISSION_LIMIT;
  } catch (error: any) {
    // Default to false to avoid blocking submissions on error
    return false;
  }
};

// New function to mark a submission as viewed
export const markSubmissionAsViewed = async (submissionId: string, userId: string): Promise<void> => {
  try {
    // First check if this viewed submission already exists
    const { data: existingData, error: checkError } = await supabase
      .from('viewed_submissions')
      .select('*')
      .eq('submission_id', submissionId)
      .eq('user_id', userId)
      .single();
    
    if (checkError && checkError.code !== 'PGRST116') {
      // PGRST116 is the "no rows returned" error code which we expect if the entry doesn't exist
      throw checkError;
    }
    
    // If the viewed submission doesn't exist, create it
    if (!existingData) {
      const { error: insertError } = await supabase
        .from('viewed_submissions')
        .insert({
          submission_id: submissionId,
          user_id: userId,
          viewed_at: new Date().toISOString()
        });
      
      if (insertError) {
        throw insertError;
      }
    }
  } catch (error) {
    throw error;
  }
};

// New function to get all viewed submissions for a user
export const getViewedSubmissions = async (userId: string): Promise<string[]> => {
  try {
    const { data, error } = await supabase
      .from('viewed_submissions')
      .select('submission_id')
      .eq('user_id', userId);
    
    if (error) {
      throw error;
    }
    
    // Extract just the submission_id values into an array
    const viewedIds = data?.map(item => item.submission_id) || [];
    
    return viewedIds;
  } catch (error) {
    return [];
  }
};
