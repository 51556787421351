import { useState, useEffect, useCallback, useRef } from 'react';
import { ImageSubmission } from '@/types';
import { useAuth } from '@/context/AuthContext';
import { toast } from '@/components/ui/use-toast';
import { supabase } from '@/integrations/supabase/client';
import { 
  fetchUserSubmissions, 
  uploadImageToStorage, 
  createSubmission, 
  updateSubmissionStatus,
  getUserSubmissionCount,
  FREE_TIER_SUBMISSION_LIMIT,
  hasReachedSubmissionLimit,
  fetchAllPendingSubmissions,
  getViewedSubmissions,
  markSubmissionAsViewed
} from '@/services/submissionService';

export const useSubmissionsData = () => {
  const { user } = useAuth();
  const [submissions, setSubmissions] = useState<ImageSubmission[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [submissionCount, setSubmissionCount] = useState<number>(0);
  const [hasReachedLimit, setHasReachedLimit] = useState<boolean>(false);
  const [allPendingSubmissions, setAllPendingSubmissions] = useState<ImageSubmission[]>([]);
  // Track if we're currently on the admin page
  const [isOnAdminPage, setIsOnAdminPage] = useState<boolean>(false);
  // Track viewed submission IDs
  const [viewedSubmissionIds, setViewedSubmissionIds] = useState<string[]>([]);
  
  // Use refs to prevent duplicate requests
  const isLoadingRef = useRef(false);
  const isAdminLoadingRef = useRef(false);

  // Set the admin page status
  const setAdminPageStatus = useCallback((status: boolean) => {
    setIsOnAdminPage(status);
  }, []);

  // Load viewed submissions
  const loadViewedSubmissions = async () => {
    if (!user) return;
    
    try {
      console.log('Loading viewed submissions for user:', user.id);
      const viewedIds = await getViewedSubmissions(user.id);
      console.log('Loaded viewed submissions:', viewedIds);
      setViewedSubmissionIds(viewedIds);
    } catch (error) {
      console.error('Error loading viewed submissions:', error);
    }
  };

  // Mark a submission as viewed
  const markSubmissionViewed = async (submissionId: string) => {
    if (!user) return;
    
    try {
      console.log('Marking submission as viewed:', submissionId);
      
      // Call the service function to update the database
      await markSubmissionAsViewed(submissionId, user.id);
      
      // Update local state to reflect the change immediately
      setViewedSubmissionIds(prev => {
        if (!prev.includes(submissionId)) {
          return [...prev, submissionId];
        }
        return prev;
      });
    } catch (error) {
      console.error('Error marking submission as viewed:', error);
    }
  };

  // Process submissions and apply blur for free users who are over the limit
  // Also mark submissions as expired if they have passed their expiration date
  const processSubmissions = (submissionsData: ImageSubmission[]) => {
    if (!user) return submissionsData;
    
    const now = new Date();
    
    // Mark expired submissions
    const processedSubmissions = submissionsData.map(sub => {
      // Check if submission is expired (for non-pro users)
      const isExpired = !user.isPro && 
                        sub.expiresAt && 
                        new Date(sub.expiresAt) < now;
      
      // Check if submission has been viewed
      const isViewed = viewedSubmissionIds.includes(sub.id);
      
      return {
        ...sub,
        isExpired: isExpired || false,
        isViewed: isViewed || false
      };
    });
    
    // For Pro users, return submissions as is (with expired flag set but they can still access)
    if (user.isPro) return processedSubmissions;
    
    // For free users, sort approved submissions by date (newest first)
    const approvedSubmissions = processedSubmissions
      .filter(sub => sub.status === 'approved')
      .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
    
    // Other submissions remain unchanged
    const otherSubmissions = processedSubmissions.filter(sub => sub.status !== 'approved');
    
    // If there are more approved submissions than the limit
    if (approvedSubmissions.length > FREE_TIER_SUBMISSION_LIMIT) {
      // Blur the newest submissions over the limit
      const toBlurCount = approvedSubmissions.length - FREE_TIER_SUBMISSION_LIMIT;
      
      // Mark the newest ones for blurring (those at the beginning of the array)
      const blurredSubmissions = approvedSubmissions.slice(0, toBlurCount).map(sub => ({
        ...sub,
        isOverLimit: true
      }));
      
      // Keep the older ones visible
      const visibleSubmissions = approvedSubmissions.slice(toBlurCount);
      
      // Return combined list with correct newest-first order (blurred ones first, then visible)
      return [...blurredSubmissions, ...visibleSubmissions, ...otherSubmissions];
    }
    
    // If under the limit, return all submissions unblurred
    return [...approvedSubmissions, ...otherSubmissions];
  };

  // Count submissions that are actually pending review (not rejected)
  const getPendingReviewCount = (submissions: ImageSubmission[]) => {
    return submissions.filter(sub => sub.status === 'pending').length;
  };

  // Reorder submissions with approved first, then pending and rejected
  const rawSubmissions = submissions || [];
  const processedSubmissions = processSubmissions(rawSubmissions);
  
  const approvedSubmissions = processedSubmissions
    .filter((sub) => sub.status === 'approved')
    .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
    
  // Include both pending and rejected submissions in pendingSubmissions
  const pendingSubmissions = processedSubmissions
    .filter((sub) => sub.status === 'pending' || sub.status === 'rejected')
    .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
    
  const rejectedSubmissions = processedSubmissions
    .filter((sub) => sub.status === 'rejected')
    .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());

  useEffect(() => {
    if (user) {
      // First load viewed submissions, then load regular submissions
      loadViewedSubmissions().then(() => loadSubmissions());
      loadSubmissionCount();
    } else {
      setSubmissions([]);
      setSubmissionCount(0);
      setHasReachedLimit(false);
      setAllPendingSubmissions([]);
      setViewedSubmissionIds([]);
    }
  }, [user]);
  
  // Only load admin submissions when the admin page status changes
  useEffect(() => {
    if (user?.isAdmin && isOnAdminPage) {
      loadAllPendingSubmissions();
    } else {
      // Clear admin submissions when not on admin page
      setAllPendingSubmissions([]);
    }
  }, [user, isOnAdminPage]);

  const loadSubmissions = async () => {
    if (!user || isLoadingRef.current) return;
    
    isLoadingRef.current = true;
    setIsLoading(true);
    setError(null);
    
    try {
      const submissionsData = await fetchUserSubmissions(user.id);
      setSubmissions(submissionsData);
    } catch (error: any) {
      console.error('Error in loadSubmissions:', error);
      setError('Failed to load your submissions');
      toast({
        title: "Error",
        description: "Failed to load your submissions. Please try again.",
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
      isLoadingRef.current = false;
    }
  };

  const loadSubmissionCount = async () => {
    if (!user) return;
    
    try {
      const count = await getUserSubmissionCount(user.id);
      setSubmissionCount(count);
      
      // Check if user has reached their limit
      const limitReached = await hasReachedSubmissionLimit(user.id, user.isPro);
      setHasReachedLimit(limitReached);
    } catch (error) {
      console.error('Error loading submission count:', error);
    }
  };

  // Load all pending submissions (for admin use)
  const loadAllPendingSubmissions = async () => {
    if (!user || !user.isAdmin || !isOnAdminPage || isAdminLoadingRef.current) {
      return;
    }
    
    isAdminLoadingRef.current = true;
    setIsLoading(true);
    setError(null);
    
    try {
      const allPendingData = await fetchAllPendingSubmissions();
      setAllPendingSubmissions(allPendingData);
    } catch (error: any) {
      console.error('Error loading all pending submissions:', error);
      setError('Failed to load all pending submissions');
      toast({
        title: "Error",
        description: "Failed to load pending submissions. Please try again.",
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
      isAdminLoadingRef.current = false;
    }
  };

  // Make refreshAllPendingSubmissions a callback
  const refreshAllPendingSubmissions = useCallback(async () => {
    if (user?.isAdmin && isOnAdminPage && !isAdminLoadingRef.current) {
      await loadAllPendingSubmissions();
    }
  }, [user, isOnAdminPage]);

  const submitImage = async (userId: string, imageFile: File, caption?: string) => {
    setIsLoading(true);
    setError(null);
    
    try {
      // Note: We're removing the check that prevents submissions
      // Instead, we'll just upload the image and create the submission
      
      // Upload image
      const publicUrl = await uploadImageToStorage(userId, imageFile);
      
      // Create submission with caption
      const submissionData = await createSubmission(userId, publicUrl, caption);
      
      // Add the new submission to the state if the user is the recipient
      if (user && user.id === userId) {
        const newSubmission: ImageSubmission = {
          id: submissionData.id,
          userId: submissionData.user_id,
          imageUrl: submissionData.image_url,
          blurredImageUrl: submissionData.blurred_image_url || submissionData.image_url,
          caption: submissionData.caption,
          status: submissionData.status as 'pending' | 'approved' | 'rejected',
          createdAt: new Date(submissionData.created_at),
          expiresAt: submissionData.expires_at ? new Date(submissionData.expires_at) : undefined,
          isOverLimit: false,
          isExpired: false
        };
        
        setSubmissions((prev) => [newSubmission, ...prev]);
        
        // Update the submission count (only for pending submissions)
        loadSubmissionCount();
      }
      
      toast({
        title: "Success",
        description: 'Image submitted successfully!'
      });
    } catch (error: any) {
      console.error('Error submitting image:', error);
      setError(error.message || 'Failed to submit image');
      toast({
        title: "Error",
        description: error.message || 'Failed to submit image',
        variant: "destructive"
      });
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const approveSubmission = async (submissionId: string) => {
    setIsLoading(true);
    
    try {
      await updateSubmissionStatus(submissionId, 'approved');
      
      // Update local state for user's submissions
      setSubmissions((prev) =>
        prev.map((sub) =>
          sub.id === submissionId ? { ...sub, status: 'approved' } : sub
        )
      );
      
      // Also update the all pending submissions list for admins
      setAllPendingSubmissions((prev) => 
        prev.filter((sub) => sub.id !== submissionId)
      );
      
      // Refresh submission count since an approval reduces the pending count
      loadSubmissionCount();
      
      toast({
        title: "Success",
        description: 'Image approved!'
      });
    } catch (error: any) {
      setError(error.message || 'Failed to approve submission');
      toast({
        title: "Error",
        description: error.message || 'Failed to approve submission',
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
    }
  };

  const rejectSubmission = async (submissionId: string) => {
    setIsLoading(true);
    
    try {
      await updateSubmissionStatus(submissionId, 'rejected');
      
      // Update local state for user's submissions
      setSubmissions((prev) =>
        prev.map((sub) =>
          sub.id === submissionId ? { ...sub, status: 'rejected' } : sub
        )
      );
      
      // Keep rejected submissions in the allPendingSubmissions but update their status
      setAllPendingSubmissions((prev) => 
        prev.map((sub) =>
          sub.id === submissionId ? { ...sub, status: 'rejected' } : sub
        )
      );
      
      // Refresh submission count since a rejection changes the pending count
      loadSubmissionCount();
      
      toast({
        title: "Success",
        description: 'Image rejected'
      });
    } catch (error: any) {
      setError(error.message || 'Failed to reject submission');
      toast({
        title: "Error",
        description: error.message || 'Failed to reject submission',
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getSubmissionsByUserId = (userId: string) => {
    return submissions.filter((sub) => sub.userId === userId);
  };

  const generateShareableImage = (imageUrl: string) => {
    // For sharing, we'll return just the original URL
    // The watermarking will be handled client-side when sharing
    return imageUrl;
  };

  // Calculate viewed and unviewed counts
  const viewedApprovedCount = approvedSubmissions.filter(sub => viewedSubmissionIds.includes(sub.id)).length;
  const unviewedApprovedCount = approvedSubmissions.length - viewedApprovedCount;

  // Calculate pending review counts (exclude rejected items)
  const pendingReviewCount = getPendingReviewCount(pendingSubmissions);

  // Calculate the number of visible (non-blurred) approved submissions
  // This should count expired submissions (they still count against the limit)
  const visibleApprovedCount = user?.isPro 
    ? approvedSubmissions.length 
    : Math.min(approvedSubmissions.length, FREE_TIER_SUBMISSION_LIMIT);
  
  // Calculate the number of blurred submissions (those over the limit)
  const blurredApprovedCount = user?.isPro 
    ? 0 
    : Math.max(approvedSubmissions.length - FREE_TIER_SUBMISSION_LIMIT, 0);

  // Count of expired submissions (only relevant for non-pro users)
  const expiredSubmissionsCount = user?.isPro
    ? 0
    : approvedSubmissions.filter(sub => sub.isExpired).length;

  // Calculate progress percentage for free users - only count approved submissions
  const submissionLimitPercentage = user?.isPro 
    ? 100 
    : Math.min(Math.round((submissionCount / FREE_TIER_SUBMISSION_LIMIT) * 100), 100);

  return {
    submissions,
    approvedSubmissions,
    pendingSubmissions,
    rejectedSubmissions,
    isLoading,
    error,
    submitImage,
    approveSubmission,
    rejectSubmission,
    getSubmissionsByUserId,
    generateShareableImage,
    refreshSubmissions: loadSubmissions,
    refreshAllPendingSubmissions,
    allPendingSubmissions,
    submissionCount,
    submissionLimit: FREE_TIER_SUBMISSION_LIMIT,
    hasReachedLimit,
    submissionLimitPercentage,
    visibleApprovedCount,
    blurredApprovedCount,
    expiredSubmissionsCount,
    setAdminPageStatus,
    viewedSubmissionIds,
    viewedApprovedCount,
    unviewedApprovedCount,
    markSubmissionViewed,
    pendingReviewCount
  };
};
