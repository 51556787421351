
import { supabase } from '@/integrations/supabase/client';
import { User } from '@supabase/supabase-js';
import { AppUser } from '@/context/AuthContext';

export const fetchUserProfile = async (user: User): Promise<{ data: AppUser | null, error: string | null }> => {
  try {
    // Using a separate try/catch block to prevent triggering infinite loops
    let profileData = null;
    let profileError = null;
    
    try {
      // Use the get_profile_by_id RPC function to get the profile data
      const { data: profile, error } = await supabase
        .rpc('get_profile_by_id', { user_id: user.id });
      
      if (error) {
        profileError = error.message;
      } else {
        profileData = profile?.[0] || null;
      }
    } catch (dbError: any) {
      profileError = dbError.message;
    }
    
    // Create an enhanced user with profile data (or default values if no profile data)
    const enhancedUser: AppUser = {
      ...user,
      username: profileData?.username || user.user_metadata?.username || user.email?.split('@')[0] || 'user',
      isPro: !!profileData?.is_pro, // Convert to boolean
      isAdmin: !!profileData?.is_admin, // Convert to boolean
      proExpiresAt: profileData?.pro_expires_at || null
    };
    
    return { data: enhancedUser, error: profileError };
  } catch (error: any) {
    // Return a minimal profile to prevent UI from breaking
    const fallbackUser: AppUser = {
      ...user,
      username: user.user_metadata?.username || user.email?.split('@')[0] || 'user',
      isPro: false,
      isAdmin: false,
      proExpiresAt: null
    };
    
    return { data: fallbackUser, error: error.message };
  }
};

export const loginUser = async (email: string, password: string) => {
  try {
    const { data, error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });
    
    if (error) {
      throw error;
    }
    
    return { data, error: null };
  } catch (error: any) {
    return { data: null, error: error.message };
  }
};

export const signupUser = async (username: string, email: string, password: string) => {
  try {
    // First, sign up the user with email and password
    const { data: signUpData, error: signUpError } = await supabase.auth.signUp({
      email: email,
      password: password,
      options: {
        data: {
          username: username,
        },
      },
    });
    
    if (signUpError) {
      throw signUpError;
    }
    
    // After successful signup, update the user's profile with the username
    // But don't throw an error if this fails - we just log it and continue
    if (signUpData.user) {
      try {
        const { error: profileError } = await supabase
          .from('profiles')
          .insert([
            { id: signUpData.user.id, username: username }
          ]);
        
        if (profileError) {
          // Important: We're logging the error but NOT throwing it anymore
          // This allows the user to continue even if the profile creation fails
        }
      } catch (profileErr) {
        // Just log the error but don't throw it
      }
    }
    
    return { data: signUpData, error: null };
  } catch (error: any) {
    return { data: null, error: error.message };
  }
};

export const logoutUser = async () => {
  try {
    // Check if there's a valid session first
    const { data: sessionData } = await supabase.auth.getSession();
    
    // Only attempt to sign out if there's an active session
    if (sessionData?.session) {
      const { error } = await supabase.auth.signOut();
      
      if (error) {
        throw error;
      }
    }
    
    return { error: null };
  } catch (error: any) {
    return { error: error.message };
  }
};

export const checkUsernameAvailability = async (username: string): Promise<boolean> => {
  try {
    // Use the check_username_exists RPC function instead of querying the table directly
    // This prevents the infinite recursion in RLS policies
    const { data, error } = await supabase
      .rpc('check_username_exists', { username_to_check: username });
    
    if (error) {
      throw error;
    }
    
    // Return the inverse of the result since we want to know if it's available (not exists)
    return !data;
  } catch (error: any) {
    throw error;
  }
};

export const upgradeUserAccount = async (userId: string) => {
  try {
    // Calculate the proExpiresAt timestamp (7 days from now)
    const proExpiresAt = new Date();
    proExpiresAt.setDate(proExpiresAt.getDate() + 7);
    
    const { data, error } = await supabase
      .from('profiles')
      .update({ is_pro: true, pro_expires_at: proExpiresAt.toISOString() })
      .eq('id', userId);
    
    if (error) {
      throw error;
    }
    
    return { data, error: null };
  } catch (error: any) {
    return { data: null, error: error.message };
  }
};

// Add the activateProByUsername function
export const activateProByUsername = async (username: string, daysValid: number = 7): Promise<{ data: any, error: string | null }> => {
  try {
    const { data, error } = await supabase.functions.invoke('activate-pro-by-username', {
      body: { username, daysValid }
    });
    
    if (error) {
      throw error;
    }
    
    return { data, error: null };
  } catch (error: any) {
    return { data: null, error: error.message };
  }
};
