
import React, { useRef, useState } from 'react';
import { Camera, Upload, X, AlertCircle, Loader2, Lock } from 'lucide-react';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { Textarea } from '@/components/ui/textarea';
import { Label } from '@/components/ui/label';

interface SubmissionFormProps {
  username: string | undefined;
  isLoading: boolean;
  onSubmit: () => Promise<void>;
  selectedFile: File | null;
  previewUrl: string | null;
  onReset: () => void;
  handleFileSelect: (file: File) => void;
  hasReachedLimit?: boolean;
  caption?: string;
  onCaptionChange?: (value: string) => void;
}

const SubmissionForm: React.FC<SubmissionFormProps> = ({ 
  username, 
  isLoading, 
  onSubmit, 
  selectedFile, 
  previewUrl, 
  onReset,
  handleFileSelect,
  hasReachedLimit = false,
  caption = '',
  onCaptionChange
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dropZoneRef = useRef<HTMLDivElement>(null);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };
  
  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
  };
  
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onFileSelect(e.dataTransfer.files[0]);
    }
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      onFileSelect(e.target.files[0]);
    }
  };
  
  const onFileSelect = (file: File) => {
    if (!file.type.startsWith('image/')) {
      return;
    }
    
    if (file.size > 5 * 1024 * 1024) {
      return;
    }
    
    // Call the passed-in handler from the parent component
    handleFileSelect(file);
  };
  
  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSubmitImage = async () => {
    if (selectedFile) {
      await onSubmit();
    }
  };

  const handleCaptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onCaptionChange) {
      onCaptionChange(e.target.value);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-[#0a0a0a] relative overflow-hidden">
      {/* Floating Emojis */}
      <div className="floating-element left-[10%] top-[20%]">
        <span className="text-4xl">📸</span>
      </div>
      <div className="floating-element right-[15%] top-[15%]" style={{ animationDelay: '1s' }}>
        <span className="text-4xl">🎭</span>
      </div>
      <div className="floating-element left-[20%] bottom-[20%]" style={{ animationDelay: '1.5s' }}>
        <span className="text-4xl">🌟</span>
      </div>
      <div className="floating-element right-[10%] bottom-[25%]" style={{ animationDelay: '2s' }}>
        <span className="text-4xl">✨</span>
      </div>

      <header className="py-4 px-6 flex justify-between items-center border-b border-white/10 relative z-10">
        <Link to="/" className="text-xl font-bold bg-gradient-to-r from-purple-500 via-pink-500 to-amber-500 bg-clip-text text-transparent">
          DropSnap
        </Link>
      </header>
      
      <main className="flex-1 flex items-center justify-center p-4 relative z-10">
        <Card className="w-full max-w-md mx-auto bg-[#111111] border border-white/10">
          <CardContent className="p-6">
            <div className="text-center mb-6">
              <h1 className="text-2xl font-bold text-white mb-2">
                Send a Snap to @{username} 📸
              </h1>
              <p className="text-white/60">
                No names. No limits. Memes, roasts, throwbacks, chaos welcomed. 😂
              </p>
            </div>
            
            {/* Removed all warnings about free tier limit */}
            
            {selectedFile && previewUrl ? (
              <div className="space-y-4">
                <div className="relative aspect-square rounded-lg overflow-hidden border border-white/10">
                  <img 
                    src={previewUrl} 
                    alt="Preview" 
                    className="w-full h-full object-cover"
                  />
                  <button
                    onClick={onReset}
                    className="absolute top-2 right-2 bg-black/50 rounded-full p-1 text-white hover:bg-black/70 transition-all"
                  >
                    <X className="h-5 w-5" />
                  </button>
                </div>
                
                <div className="space-y-2">
                  <Label htmlFor="caption" className="text-white">Caption (optional)</Label>
                  <Textarea 
                    id="caption"
                    placeholder="Add a caption to your image..." 
                    value={caption}
                    onChange={handleCaptionChange}
                    className="resize-none bg-white/5 border-white/10 text-white placeholder:text-white/40"
                  />
                </div>
                
                <Button
                  onClick={handleSubmitImage}
                  className="w-full dropsnap-button"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Submitting...
                    </>
                  ) : (
                    'Submit Image'
                  )}
                </Button>
              </div>
            ) : (
              <div
                ref={dropZoneRef}
                className={`border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-colors
                  ${isDragging 
                    ? 'border-purple-500 bg-purple-500/10' 
                    : 'border-white/10 hover:border-white/20 bg-white/5'}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={triggerFileInput}
              >
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  onChange={handleFileInputChange}
                  className="hidden"
                />
                <div className="mx-auto w-10 h-10 rounded-full bg-white/5 flex items-center justify-center mb-4">
                  <Upload className="h-6 w-6 text-white/60" />
                </div>
                <p className="text-white font-medium mb-1">Drop your image here</p>
                <p className="text-white/60 text-sm">
                  or click to select a file
                </p>
                <p className="text-white/40 text-xs mt-2">
                  Max file size: 5MB
                </p>
              </div>
            )}
            
            <div className="mt-6 bg-white/5 p-4 rounded-lg flex items-start space-x-2">
              <AlertCircle className="h-5 w-5 text-white/60 flex-shrink-0 mt-0.5" />
              <p className="text-sm text-white/60">
                ⚡ Totally anonymous. @{username} won't have a clue it's you. 👻
              </p>
            </div>
          </CardContent>
        </Card>
      </main>
    </div>
  );
};

export default SubmissionForm;
