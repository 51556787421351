import React, { useState, useEffect } from 'react';
import { useSubmissionContext } from '@/context/SubmissionContext';
import Navbar from '@/components/Navbar';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { CheckCircle, XCircle, ChevronLeft, ChevronRight, Maximize, Clock, User } from 'lucide-react';
import { toast } from 'sonner';
import ImagePopup from '@/components/ImagePopup';
import { supabase } from '@/integrations/supabase/client';
import { ImageSubmission } from '@/types';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { activateProByUsername } from '@/services/auth';
import { useNavigate } from 'react-router-dom';

const proActivationSchema = z.object({
  username: z.string().min(3, 'Username must be at least 3 characters'),
});

const Admin = () => {
  const { 
    allPendingSubmissions, 
    approveSubmission, 
    rejectSubmission, 
    isLoading, 
    generateShareableImage,
    refreshAllPendingSubmissions,
    setAdminPageStatus,
    pendingReviewCount
  } = useSubmissionContext();
  
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [swipeDirection, setSwipeDirection] = useState<'left' | 'right' | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [userInfo, setUserInfo] = useState<Record<string, { username: string; email?: string }>>({}); 
  const [activatingPro, setActivatingPro] = useState(false);
  const [loadingError, setLoadingError] = useState<string | null>(null);
  
  // Set admin page status when component mounts and cleans up
  useEffect(() => {
    console.log('Admin component mounted, setting admin page status to true');
    setAdminPageStatus(true);
    
    return () => {
      console.log('Admin component unmounted, setting admin page status to false');
      setAdminPageStatus(false);
    };
  }, [setAdminPageStatus]);
  
  // Refresh all pending submissions when component mounts
  useEffect(() => {
    console.log('Admin component mounted, refreshing pending submissions');
    const loadData = async () => {
      setLoadingError(null);
      try {
        await refreshAllPendingSubmissions();
        console.log('Refreshed pending submissions, count:', allPendingSubmissions?.length);
      } catch (error) {
        console.error('Error refreshing submissions:', error);
        setLoadingError('Failed to load submissions. Please try again.');
        toast.error('Failed to load pending submissions');
      }
    };
    
    loadData();
  }, [refreshAllPendingSubmissions]);
  
  // Log when allPendingSubmissions changes
  useEffect(() => {
    console.log('allPendingSubmissions updated, count:', allPendingSubmissions?.length);
  }, [allPendingSubmissions]);
  
  const proActivationForm = useForm<z.infer<typeof proActivationSchema>>({
    resolver: zodResolver(proActivationSchema),
    defaultValues: {
      username: '',
    },
  });
  
  // Fetch user information for all pending submissions
  useEffect(() => {
    const fetchUserInfo = async () => {
      if (!allPendingSubmissions || allPendingSubmissions.length === 0) {
        console.log('No pending submissions to fetch user info for');
        return;
      }
      
      const userIds = allPendingSubmissions.map(sub => sub.userId);
      const uniqueUserIds = [...new Set(userIds)];
      
      console.log('Fetching user info for user IDs:', uniqueUserIds);
      
      const userInfoMap: Record<string, { username: string; email?: string }> = {};
      
      for (const userId of uniqueUserIds) {
        try {
          // Use the get_profile_by_id RPC function instead of querying the table directly
          // This prevents the infinite recursion in RLS policies
          const { data: profileData, error: profileError } = await supabase
            .rpc('get_profile_by_id', { user_id: userId });
            
          if (profileError) {
            console.error('Error fetching profile data:', profileError);
          }
            
          // Get user data (we don't have admin API access in the frontend)
          const userData = null; // In a real scenario, we'd fetch this from a secure backend
            
          if (profileData && !profileError && profileData.length > 0) {
            userInfoMap[userId] = { 
              username: profileData[0].username,
              email: userData?.email
            };
          } else {
            // Fallback if neither is available
            userInfoMap[userId] = { username: 'Unknown User' };
          }
        } catch (err) {
          console.error('Error fetching user info:', err);
          userInfoMap[userId] = { username: 'Unknown User' };
        }
      }
      
      setUserInfo(userInfoMap);
    };
    
    if (allPendingSubmissions && allPendingSubmissions.length > 0) {
      fetchUserInfo();
    }
  }, [allPendingSubmissions]);
  
  // Helper function to display user identifier
  const getUserDisplayName = (userId: string) => {
    const info = userInfo[userId];
    if (!info) return 'Loading...';
    
    if (info.username !== 'Unknown User') {
      return info.username;
    } else if (info.email) {
      return info.email;
    } else {
      return `User #${userId.substring(0, 8)}`;
    }
  };
  
  const handleApprove = async (id: string) => {
    setSwipeDirection('right');
    await approveSubmission(id);
    setTimeout(() => {
      setSwipeDirection(null);
      if (currentIndex < allPendingSubmissions.length - 1) {
        setCurrentIndex(currentIndex + 1);
      }
    }, 500);
  };
  
  const handleReject = async (id: string) => {
    setSwipeDirection('left');
    
    try {
      // First update the status to rejected - we're now keeping rejected items in the list with a rejected status
      await rejectSubmission(id);

      // We no longer delete the file from storage as rejected submissions stay visible
      
      // If we're at the last item, stay where we are since rejected items stay in the list
      setTimeout(() => {
        setSwipeDirection(null);
      }, 500);
      
    } catch (error) {
      console.error('Error in rejection process:', error);
      toast.error('There was an error processing the rejection');
    }
  };
  
  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  
  const handleNext = () => {
    if (currentIndex < allPendingSubmissions.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      toast.info('No more submissions to review');
    }
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };
  
  // Calculate days remaining for auto-delete if expiration date exists
  const getDaysRemaining = (submission?: ImageSubmission) => {
    if (!submission || !submission.expiresAt) return null;
    
    const now = new Date();
    const expiresAt = new Date(submission.expiresAt);
    const diffTime = expiresAt.getTime() - now.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    return diffDays > 0 ? diffDays : 0;
  };
  
  // Handle manual pro activation by username
  const handleProActivation = async (values: z.infer<typeof proActivationSchema>) => {
    try {
      setActivatingPro(true);
      
      // Use our updated activateProByUsername function
      const { data, error } = await activateProByUsername(values.username, 7);
      
      if (error) {
        throw new Error(error);
      }
      
      toast.success(`Pro access granted to ${values.username} for 7 days`);
      proActivationForm.reset();
    } catch (error: any) {
      console.error('Error activating pro:', error);
      toast.error(error.message || 'Failed to activate pro access');
    } finally {
      setActivatingPro(false);
    }
  };

  // Filter submissions to show only those pending review (not rejected ones)
  const submissionsUnderReview = allPendingSubmissions?.filter(sub => sub.status === 'pending') || [];
  
  return (
    <div className="min-h-screen bg-gradient-to-br from-white to-gray-50">
      <Navbar />
      
      <div className="pt-24 pb-12 px-4">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-10">
            <h1 className="text-3xl font-bold dropsnap-gradient bg-clip-text text-transparent">
              Admin Panel
            </h1>
            <p className="text-gray-600 mt-2">
              Manage submissions and user accounts
            </p>
            {loadingError && (
              <p className="text-red-500 mt-2 bg-red-50 p-2 rounded border border-red-200">
                {loadingError}
              </p>
            )}
          </div>
          
          <Tabs defaultValue="submissions" className="mb-10">
            <TabsList className="mx-auto mb-6">
              <TabsTrigger value="submissions">Submissions</TabsTrigger>
              <TabsTrigger value="users">User Management</TabsTrigger>
            </TabsList>
            
            <TabsContent value="submissions">
              {(isLoading) ? (
                <Card className="dropsnap-card text-center py-12">
                  <div className="flex flex-col items-center justify-center">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-dropsnap-pink mb-4"></div>
                    <p className="text-gray-600">Loading submissions...</p>
                  </div>
                </Card>
              ) : (!submissionsUnderReview || submissionsUnderReview.length === 0) ? (
                <Card className="dropsnap-card text-center py-12">
                  <CheckCircle size={48} className="mx-auto text-green-500 mb-4" />
                  <h3 className="text-xl font-medium text-gray-700 mb-2">All Caught Up!</h3>
                  <p className="text-gray-500">There are no pending submissions to review.</p>
                  <Button
                    className="mt-4"
                    onClick={() => refreshAllPendingSubmissions()}
                    disabled={isLoading}
                  >
                    {isLoading ? 'Refreshing...' : 'Refresh Submissions'}
                  </Button>
                </Card>
              ) : (
                <div className="flex flex-col items-center">
                  <div className="relative w-full max-w-md mb-8">
                    <div
                      className={`relative ${
                        swipeDirection === 'left'
                          ? 'animate-swipe-left'
                          : swipeDirection === 'right'
                          ? 'animate-swipe-right'
                          : ''
                      }`}
                    >
                      <Card className="overflow-hidden shadow-lg rounded-2xl">
                        <div className="aspect-w-4 aspect-h-3 w-full relative">
                          <div className="bg-gray-50 flex items-center justify-center h-72">
                            <img
                              src={allPendingSubmissions[currentIndex]?.imageUrl}
                              alt="Submission"
                              className="h-72 object-contain w-full cursor-pointer"
                              onClick={openPopup}
                            />
                          </div>
                          <Button
                            variant="ghost"
                            size="icon"
                            className="absolute top-2 right-2 bg-black/30 text-white hover:bg-black/50"
                            onClick={openPopup}
                          >
                            <Maximize size={16} />
                          </Button>
                        </div>
                        <div className="p-4">
                          {allPendingSubmissions[currentIndex]?.caption && (
                            <p className="text-sm text-gray-700 mb-3 italic">
                              "{allPendingSubmissions[currentIndex]?.caption}"
                            </p>
                          )}
                          <div className="flex justify-between items-center">
                            <p className="text-sm text-gray-500">
                              Submission #{allPendingSubmissions[currentIndex]?.id.substring(0, 8)}
                            </p>
                            <p className="text-sm text-gray-500">
                              {new Date(
                                allPendingSubmissions[currentIndex]?.createdAt
                              ).toLocaleDateString()}
                            </p>
                          </div>
                          <div className="flex justify-between items-center mt-1">
                            <div className="flex items-center">
                              <User size={12} className="mr-1 text-gray-500" />
                              <p className="text-sm text-gray-700 font-medium">
                                {getUserDisplayName(allPendingSubmissions[currentIndex]?.userId)}
                              </p>
                            </div>
                            
                            {getDaysRemaining(allPendingSubmissions[currentIndex]) !== null && (
                              <div className="flex items-center text-xs text-amber-600">
                                <Clock size={12} className="mr-1" />
                                {getDaysRemaining(allPendingSubmissions[currentIndex]) === 0 
                                  ? "Expires today" 
                                  : `${getDaysRemaining(allPendingSubmissions[currentIndex])}d left`}
                              </div>
                            )}
                          </div>
                          
                          {/* Show status label for rejected submissions */}
                          {allPendingSubmissions[currentIndex]?.status === 'rejected' && (
                            <div className="mt-2 bg-red-50 text-red-600 p-2 rounded text-sm">
                              Rejected by AI due to inappropriate content
                            </div>
                          )}
                        </div>
                      </Card>
                    </div>
                  </div>
                  
                  <div className="flex items-center justify-center gap-4">
                    <Button
                      variant="outline"
                      size="icon"
                      className="rounded-full w-10 h-10"
                      onClick={handlePrev}
                      disabled={currentIndex === 0 || isLoading}
                    >
                      <ChevronLeft size={20} />
                    </Button>
                    
                    <Button
                      variant="destructive"
                      className="rounded-full w-14 h-14"
                      size="icon"
                      onClick={() => handleReject(allPendingSubmissions[currentIndex]?.id)}
                      disabled={isLoading || allPendingSubmissions[currentIndex]?.status === 'rejected'}
                    >
                      <XCircle size={24} />
                    </Button>
                    
                    <Button
                      variant="default"
                      className="rounded-full w-14 h-14 bg-green-500 hover:bg-green-600"
                      size="icon"
                      onClick={() => handleApprove(allPendingSubmissions[currentIndex]?.id)}
                      disabled={isLoading || allPendingSubmissions[currentIndex]?.status === 'rejected'}
                    >
                      <CheckCircle size={24} />
                    </Button>
                    
                    <Button
                      variant="outline"
                      size="icon"
                      className="rounded-full w-10 h-10"
                      onClick={handleNext}
                      disabled={currentIndex === allPendingSubmissions.length - 1 || isLoading}
                    >
                      <ChevronRight size={20} />
                    </Button>
                  </div>
                  
                  <p className="text-gray-500 text-sm mt-6">
                    {currentIndex + 1} of {allPendingSubmissions.length} submissions 
                    ({pendingReviewCount} under AI review)
                  </p>
                </div>
              )}
            </TabsContent>
            
            <TabsContent value="users">
              <Card className="bg-[#111111] border-white/10">
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-6 text-white">Manual Pro Activation</h3>
                  <p className="text-white/60 mb-6">
                    Activate Pro status for a user by their username. This will grant them
                    Pro access for 7 days.
                  </p>
                  
                  <Form {...proActivationForm}>
                    <form onSubmit={proActivationForm.handleSubmit(handleProActivation)} className="space-y-4">
                      <FormField
                        control={proActivationForm.control}
                        name="username"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel className="text-white">Username</FormLabel>
                            <FormControl>
                              <Input 
                                placeholder="Enter username" 
                                {...field}
                                className="bg-white/5 border-white/10 text-white placeholder:text-white/40"
                              />
                            </FormControl>
                            <FormMessage className="text-red-400" />
                          </FormItem>
                        )}
                      />
                      
                      <Button 
                        type="submit" 
                        className="w-full bg-dropsnap-pink hover:bg-dropsnap-pink/90"
                        disabled={activatingPro}
                      >
                        {activatingPro ? 'Activating...' : 'Activate Pro Access'}
                      </Button>
                    </form>
                  </Form>
                </div>
              </Card>
            </TabsContent>
          </Tabs>
        </div>
      </div>

      {allPendingSubmissions && allPendingSubmissions.length > 0 && (
        <ImagePopup
          isOpen={isPopupOpen}
          onClose={closePopup}
          submission={isPopupOpen ? allPendingSubmissions[currentIndex] : null}
          isPro={true} // Admins can download everything
          generateShareableImage={generateShareableImage}
        />
      )}
    </div>
  );
};

export default Admin;
