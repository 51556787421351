
import React, { useState } from 'react';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Copy, Link as LinkIcon, Download } from 'lucide-react';
import { toast } from 'sonner';

interface ShareStepsProps {
  link: string;
}

const ShareSteps: React.FC<ShareStepsProps> = ({ link }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [fallbackImageUrl, setFallbackImageUrl] = useState<string | null>(null);

  const copyToClipboard = async () => {
    try {
      // Create a temporary input element
      const tempInput = document.createElement('input');
      tempInput.value = link;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);
      
      toast.success('Link copied to clipboard!');
    } catch (error) {
      console.error('Failed to copy:', error);
      toast.error('Failed to copy link');
    }
  };

  const isInstagramBrowser = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    return userAgent.indexOf('Instagram') > -1;
  };

  const downloadStoryImage = async () => {
    setIsDownloading(true);
    setFallbackImageUrl(null);
    
    try {
      const templateUrl = 'https://dbtxkzauevlzxyigufdc.supabase.co/storage/v1/object/public/images//story-template.jpg';
      
      // Fetch the image as a blob
      const response = await fetch(templateUrl);
      
      if (!response.ok) {
        throw new Error(`Failed to fetch image: ${response.status} ${response.statusText}`);
      }
      
      const blob = await response.blob();
      
      // For Instagram browser or others where download might not work, show the image for manual saving
      if (isInstagramBrowser()) {
        const blobUrl = URL.createObjectURL(blob);
        setFallbackImageUrl(blobUrl);
        toast.info('Press and hold the image to save it, then share to your story');
        setIsDownloading(false);
        return;
      }
      
      // Try direct download for non-Instagram browsers
      try {
        // Create a temporary URL for the blob
        const blobUrl = URL.createObjectURL(blob);
        
        // Create a link element and trigger download
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = 'dropsnap-story.jpg';
        document.body.appendChild(link);
        link.click();
        
        // Clean up
        document.body.removeChild(link);
        
        // Important: Wait for download to complete before redirecting
        toast.success('Story template downloaded!', {
          duration: 3000,
          action: {
            label: 'Open Instagram',
            onClick: () => {
              window.location.href = 'instagram://story-camera';
            }
          }
        });
        
        // Revoke the URL after a short delay to ensure download completes
        setTimeout(() => {
          URL.revokeObjectURL(blobUrl);
        }, 3000);
      } catch (downloadError) {
        console.error('Direct download failed, showing fallback:', downloadError);
        // If direct download fails, fall back to showing the image
        const blobUrl = URL.createObjectURL(blob);
        setFallbackImageUrl(blobUrl);
        toast.info('Press and hold the image to save it, then share to your story');
      }
    } catch (error) {
      console.error('Failed to download story image:', error);
      toast.error('Failed to download story image');
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div className="bg-[#111111] rounded-3xl p-6">
      <div className="max-w-4xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Step 1 */}
          <div>
            <h2 className="text-xl text-white font-bold mb-4">
              Step 1: Copy your link
            </h2>
            <div 
              className="bg-white rounded-full pl-4 pr-3 py-3 flex items-center justify-between cursor-pointer group w-full"
              onClick={copyToClipboard}
              role="button"
              tabIndex={0}
            >
              <span className="text-[#FF3B9A] font-medium text-sm break-all mr-2">
                {link}
              </span>
              <div className="bg-gradient-to-r from-[#FF3B9A] to-[#FF7A3F] rounded-full p-2 flex-shrink-0">
                <Copy size={16} className="text-white" />
              </div>
            </div>
          </div>

          {/* Step 2 */}
          <div>
            <h2 className="text-xl text-white font-bold mb-4">
              Step 2: Share on your story
            </h2>
            {fallbackImageUrl ? (
              <div className="flex flex-col items-center gap-3">
                <div className="relative bg-gray-100 rounded-lg p-2 w-full">
                  <img 
                    src={fallbackImageUrl}
                    alt="Story template"
                    className="w-full h-auto object-contain"
                  />
                  <p className="text-xs text-center text-gray-600 mt-2">
                    Press and hold on the image to save it
                  </p>
                </div>
                <Button
                  onClick={() => {
                    setFallbackImageUrl(null);
                    window.location.href = 'instagram://story-camera';
                  }}
                  className="w-full h-10 bg-gradient-to-r from-[#FF3B9A] to-[#FF7A3F] hover:opacity-90 text-white rounded-full text-sm font-medium"
                >
                  Open Instagram
                </Button>
                <Button
                  onClick={() => setFallbackImageUrl(null)}
                  variant="outline"
                  className="w-full h-10 bg-transparent border border-gray-700 text-white rounded-full text-sm font-medium"
                >
                  Close Preview
                </Button>
              </div>
            ) : (
              <Button
                onClick={downloadStoryImage}
                disabled={isDownloading}
                className="w-full h-12 bg-gradient-to-r from-[#FF3B9A] to-[#FF7A3F] hover:opacity-90 text-white rounded-full text-base font-medium"
              >
                <Download size={16} className="mr-2" />
                {isDownloading ? 'Downloading...' : 'Download & Share'}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareSteps;
