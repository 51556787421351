import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/context/AuthContext';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { LogOut, User } from 'lucide-react';

const Navbar = () => {
  const { user, logout } = useAuth();
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleLogout = async () => {
    await logout();
  };

  return (
    <nav className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
      isHomePage && !hasScrolled ? 'bg-transparent' : 'bg-black/80 backdrop-blur-md border-b border-white/5'
    }`}>
      <div className="max-w-6xl mx-auto px-4 md:px-6 py-4 flex justify-between items-center">
        <Link to="/" className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-500 via-pink-500 to-amber-500">
          DropSnap
        </Link>

        <div className="flex items-center gap-4">
          {!user && (
            <>
              <Link to="/login" className="text-sm font-medium text-white/90 hover:text-white transition-colors">
                Login
              </Link>
              <Link to="/signup">
                <Button className="rounded-full px-6 bg-gradient-to-r from-purple-500 via-pink-500 to-amber-500 text-white hover:opacity-90 transition-opacity">
                  Sign Up
                </Button>
              </Link>
            </>
          )}
          {user && (
            <div className="flex items-center gap-3">
              <Link to="/dashboard">
                <Button className="rounded-full px-6 bg-gradient-to-r from-purple-500 via-pink-500 to-amber-500 text-white hover:opacity-90 transition-opacity">
                  Dashboard
                </Button>
              </Link>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" size="icon" className="rounded-full w-10 h-10 bg-white/5 hover:bg-white/10 transition-colors">
                    <User className="h-5 w-5 text-white" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="w-48 bg-black/90 backdrop-blur-md border border-white/10">
                  <DropdownMenuItem onClick={handleLogout} className="text-red-400 cursor-pointer hover:text-red-300 hover:bg-white/5">
                    <LogOut className="mr-2 h-4 w-4" />
                    <span>Logout</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
