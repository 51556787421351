import React from 'react';
import { Progress } from '@/components/ui/progress';
import { useSubmissionContext } from '@/context/SubmissionContext';
import { Clock } from 'lucide-react';

interface SubmissionLimitBarProps {
  className?: string;
}

const SubmissionLimitBar: React.FC<SubmissionLimitBarProps> = ({
  className = ''
}) => {
  const { visibleApprovedCount, submissionLimit } = useSubmissionContext();
  
  const progress = (visibleApprovedCount / submissionLimit) * 100;

  return (
    <div className={`bg-white rounded-[24px] p-6 ${className}`}>
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-gray-900 font-medium">Submission Limit</h3>
        <span className="text-gray-900 font-medium">
          {visibleApprovedCount} / {submissionLimit}
        </span>
      </div>

      <Progress 
        value={progress} 
        className="h-2 [&>div]:bg-gradient-to-r [&>div]:from-pink-500 [&>div]:to-green-400 bg-gray-100"
      />

      <div className="flex items-center mt-4 text-gray-500 text-sm">
        <Clock size={14} className="mr-2" />
        Free accounts can see up to {submissionLimit} approved submissions.
      </div>
    </div>
  );
};

export default SubmissionLimitBar;
