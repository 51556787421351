
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';
import { useImageSubmission } from '@/hooks/useImageSubmission';

// Components
import LoadingState from '@/components/submission/LoadingState';
import UserNotFoundMessage from '@/components/submission/UserNotFoundMessage';
import SubmissionSuccess from '@/components/submission/SubmissionSuccess';
import SubmissionForm from '@/components/submission/SubmissionForm';

const Submission = () => {
  const { username } = useParams<{ username: string }>();
  const { user } = useAuth();
  const navigate = useNavigate();
  
  const {
    selectedFile,
    previewUrl,
    isSubmitted,
    targetUserId,
    isLoadingUserId,
    isLoading,
    hasReachedLimit,
    caption,
    handleFileSelect,
    handleSubmit,
    handleReset,
    handleCaptionChange
  } = useImageSubmission(username);
  
  // Removed the redirect that prevents users from accessing their own submission page
  // Now users can view their own submission page
  
  if (isLoadingUserId) {
    return <LoadingState />;
  }
  
  if (!targetUserId && !isLoadingUserId) {
    return <UserNotFoundMessage username={username} />;
  }
  
  if (isSubmitted) {
    return <SubmissionSuccess username={username} onReset={handleReset} />;
  }
  
  return (
    <SubmissionForm
      username={username}
      isLoading={isLoading}
      onSubmit={handleSubmit}
      selectedFile={selectedFile}
      previewUrl={previewUrl}
      onReset={handleReset}
      handleFileSelect={handleFileSelect}
      hasReachedLimit={hasReachedLimit}
      caption={caption}
      onCaptionChange={handleCaptionChange}
    />
  );
};

export default Submission;
