import React, { useEffect, useRef } from 'react';
import { ImageSubmission } from '@/types';

interface ShareableImageFrameProps {
  submission: ImageSubmission;
  onGenerated: (imageUrl: string) => void;
}

const ShareableImageFrame: React.FC<ShareableImageFrameProps> = ({
  submission,
  onGenerated
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const generateFrame = async () => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      // Load the user's image first
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = submission.imageUrl;

      await new Promise((resolve) => {
        img.onload = () => {
          // Calculate dimensions for a nice 9:16 ratio (Instagram Story friendly)
          const targetWidth = 1080; // Standard Instagram Story width
          const targetHeight = 1920; // Standard Instagram Story height

          // Set canvas size
          canvas.width = targetWidth;
          canvas.height = targetHeight;

          // Create background gradient
          const gradient = ctx.createLinearGradient(0, 0, targetWidth, targetHeight);
          gradient.addColorStop(0, '#0a0a0a');  // dark background
          gradient.addColorStop(1, '#1a1a1a');  // slightly lighter
          
          // Draw background
          ctx.fillStyle = gradient;
          ctx.fillRect(0, 0, targetWidth, targetHeight);

          // Calculate dimensions based on whether we have a caption
          const hasPolaroid = submission.caption && submission.caption.trim().length > 0;
          const frameWidth = targetWidth * 0.85;
          const photoHeight = frameWidth * 0.8; // 4:5 ratio for photo
          
          if (hasPolaroid) {
            const frameHeight = photoHeight + 200; // Extra space for caption
            const frameX = (targetWidth - frameWidth) / 2;
            const frameY = targetHeight * 0.25;

            // Add shadow to the Polaroid frame
            ctx.shadowColor = 'rgba(0, 0, 0, 0.4)';
            ctx.shadowBlur = 30;
            ctx.shadowOffsetX = 0;
            ctx.shadowOffsetY = 10;

            // Draw Polaroid frame
            ctx.fillStyle = '#ffffff';
            roundedRect(ctx, frameX, frameY, frameWidth, frameHeight, 24);
            ctx.fill();

            // Reset shadow
            ctx.shadowColor = 'transparent';
            ctx.shadowBlur = 0;

            // Calculate image position within Polaroid
            const imageMargin = 20;
            const imageWidth = frameWidth - (imageMargin * 2);
            const imageHeight = photoHeight - (imageMargin * 2);

            // Draw image
            ctx.save();
            roundedRect(
              ctx,
              frameX + imageMargin,
              frameY + imageMargin,
              imageWidth,
              imageHeight,
              16
            );
            ctx.clip();
            
            // Scale and center image within the frame
            const scale = Math.min(
              imageWidth / img.width,
              imageHeight / img.height
            );
            const scaledWidth = img.width * scale;
            const scaledHeight = img.height * scale;
            const imageX = frameX + imageMargin + (imageWidth - scaledWidth) / 2;
            const imageY = frameY + imageMargin + (imageHeight - scaledHeight) / 2;
            
            ctx.drawImage(img, imageX, imageY, scaledWidth, scaledHeight);
            ctx.restore();

            // Add caption
            ctx.textAlign = 'center';
            ctx.font = '42px system-ui, -apple-system, sans-serif';
            ctx.fillStyle = '#1a1a1a';
            wrapText(
              ctx,
              submission.caption,
              frameX + frameWidth / 2,
              frameY + photoHeight + 50,
              frameWidth - 60,
              50
            );
          } else {
            // No Polaroid frame - just show the image with rounded corners
            const imageWidth = frameWidth;
            const imageHeight = photoHeight;
            const imageX = (targetWidth - imageWidth) / 2;
            const imageY = targetHeight * 0.25;

            // Add shadow to the image
            ctx.shadowColor = 'rgba(0, 0, 0, 0.4)';
            ctx.shadowBlur = 30;
            ctx.shadowOffsetX = 0;
            ctx.shadowOffsetY = 10;

            // Draw image with rounded corners
            ctx.save();
            roundedRect(ctx, imageX, imageY, imageWidth, imageHeight, 24);
            ctx.clip();
            
            // Scale and center image
            const scale = Math.min(
              imageWidth / img.width,
              imageHeight / img.height
            );
            const scaledWidth = img.width * scale;
            const scaledHeight = img.height * scale;
            const centeredX = imageX + (imageWidth - scaledWidth) / 2;
            const centeredY = imageY + (imageHeight - scaledHeight) / 2;
            
            ctx.drawImage(img, centeredX, centeredY, scaledWidth, scaledHeight);
            ctx.restore();
          }

          // Add gradient text branding
          const brandingY = targetHeight - 160;
          const brandingText = 'DropSnap.link';
          ctx.font = 'bold 72px system-ui, -apple-system, sans-serif';
          ctx.textAlign = 'center';
          
          // Create gradient for text
          const textGradient = ctx.createLinearGradient(
            targetWidth / 2 - 200,
            brandingY - 50,
            targetWidth / 2 + 200,
            brandingY + 50
          );
          textGradient.addColorStop(0, '#9333ea');  // purple-600
          textGradient.addColorStop(0.5, '#ec4899'); // pink-500
          textGradient.addColorStop(1, '#f59e0b');  // amber-500
          
          ctx.fillStyle = textGradient;
          ctx.fillText(brandingText, targetWidth / 2, brandingY);

          // Add tagline
          ctx.font = '32px system-ui, -apple-system, sans-serif';
          ctx.fillStyle = 'rgba(255, 255, 255, 0.9)';
          ctx.fillText(
            'Send Anonymous Photos',
            targetWidth / 2,
            brandingY + 50
          );

          try {
            // Convert canvas to URL and call onGenerated
            const frameUrl = canvas.toDataURL('image/jpeg', 0.95);
            onGenerated(frameUrl);
          } catch (error) {
            console.error('Error generating frame:', error);
          }
          resolve(true);
        };

        img.onerror = () => {
          console.error('Error loading image');
          resolve(false);
        };
      });
    };

    // Helper function to draw rounded rectangle
    const roundedRect = (
      ctx: CanvasRenderingContext2D,
      x: number,
      y: number,
      width: number,
      height: number,
      radius: number
    ) => {
      ctx.beginPath();
      ctx.moveTo(x + radius, y);
      ctx.lineTo(x + width - radius, y);
      ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
      ctx.lineTo(x + width, y + height - radius);
      ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
      ctx.lineTo(x + radius, y + height);
      ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
      ctx.lineTo(x, y + radius);
      ctx.quadraticCurveTo(x, y, x + radius, y);
      ctx.closePath();
    };

    // Helper function to wrap text
    const wrapText = (
      ctx: CanvasRenderingContext2D,
      text: string,
      x: number,
      y: number,
      maxWidth: number,
      lineHeight: number
    ) => {
      const words = text.split(' ');
      let line = '';
      let testLine = '';
      const lines: string[] = [];

      for (let i = 0; i < words.length; i++) {
        testLine = line + words[i] + ' ';
        const metrics = ctx.measureText(testLine);
        const testWidth = metrics.width;

        if (testWidth > maxWidth && i > 0) {
          lines.push(line);
          line = words[i] + ' ';
        } else {
          line = testLine;
        }
      }
      lines.push(line);

      // Center the text block
      const totalHeight = lines.length * lineHeight;
      let currentY = y - (totalHeight / 2) + (lineHeight / 2);

      for (const line of lines) {
        ctx.fillText(line, x, currentY);
        currentY += lineHeight;
      }
    };

    generateFrame();
  }, [submission, onGenerated]);

  return (
    <canvas
      ref={canvasRef}
      className="hidden" // Hide the canvas element
    />
  );
};

export default ShareableImageFrame; 