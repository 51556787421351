
// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://dbtxkzauevlzxyigufdc.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImRidHhremF1ZXZsenh5aWd1ZmRjIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDM0MTYzNTQsImV4cCI6MjA1ODk5MjM1NH0.WF56iJXBkVNZareiMeUrfJKkzjKow9gYrtgwMZy1IrY";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY);
