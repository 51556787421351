
import React from 'react';
import { Check, Sparkles, ArrowRight } from 'lucide-react';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';

interface SubmissionSuccessProps {
  username: string | undefined;
  onReset: () => void;
}

const SubmissionSuccess: React.FC<SubmissionSuccessProps> = ({ username, onReset }) => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-[#0a0a0a] p-4 relative overflow-hidden">
      {/* Floating Emojis */}
      <div className="floating-element left-[15%] top-[15%]">
        <span className="text-4xl">🎉</span>
      </div>
      <div className="floating-element right-[20%] top-[25%]" style={{ animationDelay: '1s' }}>
        <span className="text-4xl">⭐️</span>
      </div>
      <div className="floating-element left-[25%] bottom-[20%]" style={{ animationDelay: '1.5s' }}>
        <span className="text-4xl">✨</span>
      </div>
      <div className="floating-element right-[15%] bottom-[15%]" style={{ animationDelay: '2s' }}>
        <span className="text-4xl">🌟</span>
      </div>

      <Card className="w-full max-w-md mx-auto bg-[#111111] border border-white/10 relative z-10">
        <CardContent className="p-6">
          <div className="text-center space-y-4">
            <div className="bg-green-500/10 rounded-full p-3 w-16 h-16 mx-auto flex items-center justify-center">
              <Check className="h-8 w-8 text-green-500" />
            </div>
            
            <h2 className="text-2xl font-bold text-white">Thank You!</h2>
            <p className="text-white/60">
              Your image has been submitted to @{username}. They'll review it soon.
            </p>
            
            <div className="flex flex-col gap-3 mt-2">
              <Button 
                onClick={onReset} 
                className="dropsnap-button w-full"
              >
                Submit Another Image
              </Button>
              
              <div className="relative my-2">
                <div className="absolute inset-0 flex items-center">
                  <span className="w-full border-t border-white/10"></span>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-[#111111] text-white/40">or</span>
                </div>
              </div>
              
              <div className="bg-gradient-to-r from-purple-500/20 via-pink-500/20 to-amber-500/20 rounded-xl p-4 transform transition-all hover:scale-[1.02] group cursor-pointer">
                <Link to="/signup" className="w-full">
                  <div className="space-y-2">
                    <div className="flex items-center justify-between">
                      <h3 className="text-lg font-semibold text-white flex items-center gap-2">
                        <Sparkles className="h-5 w-5 text-amber-500" />
                        Try DropSnap for yourself
                      </h3>
                      <ArrowRight className="h-5 w-5 text-white/60 group-hover:translate-x-1 transition-transform" />
                    </div>
                    <p className="text-sm text-white/60 text-left">
                      Want to collect photos from friends too? Create your free personal link!
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default SubmissionSuccess;
