import React from 'react';
import { AlertCircle } from 'lucide-react';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';

interface UserNotFoundMessageProps {
  username: string | undefined;
}

const UserNotFoundMessage: React.FC<UserNotFoundMessageProps> = ({ username }) => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-[#0a0a0a] p-4">
      <Card className="w-full max-w-md mx-auto bg-[#111111] border border-white/10">
        <CardContent className="p-6">
          <div className="text-center space-y-4">
            <div className="bg-red-500/10 rounded-full p-3 w-16 h-16 mx-auto flex items-center justify-center">
              <AlertCircle className="h-8 w-8 text-red-500" />
            </div>
            
            <h2 className="text-2xl font-bold text-white">User Not Found</h2>
            <p className="text-white/60">
              The username @{username} doesn't exist on DropSnap.
            </p>
            
            <Link to="/">
              <Button className="mt-4 dropsnap-button w-full">
                Go Back Home
              </Button>
            </Link>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default UserNotFoundMessage;
