
import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Download, Instagram, Crown, X } from 'lucide-react';
import { ImageSubmission } from '@/types';
import { toast } from 'sonner';
import ShareableImageFrame from './ShareableImageFrame';
import { Link, useNavigate } from 'react-router-dom';

interface ImagePopupProps {
  isOpen: boolean;
  onClose: () => void;
  submission: ImageSubmission | null;
  isPro?: boolean;
  generateShareableImage: (imageUrl: string) => string;
}

const ImagePopup: React.FC<ImagePopupProps> = ({
  isOpen,
  onClose,
  submission,
  isPro = false,
  generateShareableImage,
}) => {
  const [framedImageUrl, setFramedImageUrl] = useState<string | null>(null);
  const [showingFallbackImage, setShowingFallbackImage] = useState<boolean>(false);
  const navigate = useNavigate();
  
  // Reset framed image URL when popup closes
  useEffect(() => {
    if (!isOpen) {
      setFramedImageUrl(null);
      setShowingFallbackImage(false);
    }
  }, [isOpen]);
  
  if (!submission) return null;

  const isInstagramBrowser = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    return userAgent.indexOf('Instagram') > -1;
  };
  
  const handleDownload = () => {
    if (!isPro) {
      toast.info(
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <Crown size={16} className="text-white/80" />
            <p className="font-medium">Upgrade to Pro to download HD images</p>
          </div>
          <Button 
            className="dropsnap-button w-full"
            size="sm" 
            onClick={() => {
              onClose();
              navigate('/upgrade');
            }}
          >
            Upgrade Now
          </Button>
        </div>
      );
      return;
    }
    
    // Try to download the original HD image for Pro users
    try {
      if (!isInstagramBrowser()) {
        const link = document.createElement('a');
        link.href = submission.imageUrl;
        link.download = `dropsnap-${submission.id}.jpg`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        toast.success('Original HD image downloaded!');
      } else {
        // Show the image for manual saving on Instagram browser
        window.open(submission.imageUrl, '_blank');
        toast.info('Press and hold the image to save it to your device');
      }
    } catch (error) {
      console.error('Error downloading image:', error);
      toast.error('Failed to download image');
      
      // Fallback: direct user to image URL if download fails
      window.open(submission.imageUrl, '_blank');
    }
  };
  
  const handleShare = () => {
    if (submission.status !== 'approved') {
      toast.info('You can only share approved images');
      return;
    }
    
    if (!framedImageUrl) {
      toast.error('Please wait for the image to be ready');
      return;
    }
    
    // For Instagram browser or others where download might not work
    if (isInstagramBrowser()) {
      setShowingFallbackImage(true);
      toast.info('Press and hold the image to save it, then share to your story');
      return;
    }
    
    try {
      // Download the framed version for sharing
      const link = document.createElement('a');
      link.href = framedImageUrl;
      link.download = `dropsnap-${submission.id}-story.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      // Add a toast with action to open Instagram after download
      toast.success('Image downloaded with DropSnap frame!', {
        duration: 3000,
        action: {
          label: 'Open Instagram',
          onClick: () => {
            window.location.href = 'instagram://story-camera';
          }
        }
      });
      
    } catch (error) {
      console.error('Error downloading framed image:', error);
      toast.error('Failed to download framed image');
      
      // If download fails, show the fallback image view
      setShowingFallbackImage(true);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={() => onClose()}>
      <DialogContent className="max-w-4xl p-0 overflow-hidden flex flex-col max-h-[90vh] bg-[#111111]">
        {/* Add hidden DialogTitle for accessibility */}
        <DialogTitle className="sr-only">Image Preview</DialogTitle>
        
        <div className="relative flex-1 overflow-auto">
          <div className="flex items-center justify-center min-h-[200px] bg-[#111111]">
            {showingFallbackImage ? (
              <div className="relative w-full max-w-full">
                <div className="absolute top-2 right-2 z-10">
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => setShowingFallbackImage(false)}
                    className="rounded-full bg-black/50 text-white hover:bg-black/70"
                  >
                    <X size={18} />
                  </Button>
                </div>
                <img 
                  src={framedImageUrl || ''}
                  alt="Framed image for sharing"
                  className="w-full h-auto object-contain"
                />
                <div className="text-center text-white/70 text-sm p-2">
                  Press and hold on the image to save it to your device
                </div>
                <div className="flex justify-center mt-3 mb-2">
                  <Button
                    onClick={() => {
                      window.location.href = 'instagram://story-camera';
                    }}
                    className="bg-gradient-to-r from-[#FF3B9A] to-[#FF7A3F] hover:opacity-90 text-white rounded-full text-sm font-medium"
                  >
                    <Instagram size={16} className="mr-2" />
                    Open Instagram
                  </Button>
                </div>
              </div>
            ) : (
              <div className="relative max-w-full max-h-[70vh]">
                {/* Hidden ShareableImageFrame component to generate the framed version */}
                <ShareableImageFrame
                  submission={submission}
                  onGenerated={setFramedImageUrl}
                />
                {/* Show the framed image if available, otherwise show loading state */}
                {framedImageUrl ? (
                  <img 
                    src={framedImageUrl} 
                    alt="Full size image"
                    className="w-auto max-w-full h-auto max-h-[70vh] object-contain"
                  />
                ) : (
                  <div className="flex items-center justify-center h-[70vh] w-full">
                    <div className="animate-pulse text-white/60">
                      Generating preview...
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          
          {!showingFallbackImage && (
            <div className="absolute top-2 right-2 bg-black bg-opacity-30 rounded-full p-1">
              <Button
                variant="ghost"
                size="icon"
                onClick={onClose}
                className="text-white hover:bg-white/20"
              >
                <span className="sr-only">Close</span>
                ✕
              </Button>
            </div>
          )}
        </div>
        
        {!showingFallbackImage && (
          <div className="flex flex-col gap-4 p-6 border-t border-white/10">
            {submission.caption && (
              <p className="text-white/80 text-sm">
                {submission.caption}
              </p>
            )}
            
            <div className="flex flex-col gap-3">
              <Button 
                variant="outline" 
                onClick={handleDownload}
                className="bg-white/5 border-white/10 text-white hover:bg-white/10 relative overflow-hidden group"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-purple-600/20 via-pink-500/20 to-amber-500/20 group-hover:opacity-100 opacity-0 transition-opacity" />
                <Download size={18} className="mr-2" />
                Download Original Image
                <div className="ml-2 bg-gradient-to-r from-purple-600 to-pink-500 text-white text-xs px-1.5 py-0.5 rounded">
                  PRO
                </div>
              </Button>
              
              <Button 
                variant="outline" 
                onClick={handleShare}
                className="bg-white/5 border-white/10 text-white hover:bg-white/10"
                disabled={submission.status !== 'approved'}
              >
                <Instagram size={18} className="mr-2" />
                Download with Frame
              </Button>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ImagePopup;
