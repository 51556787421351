import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Card, CardContent } from '@/components/ui/card';
import { Loader2, CheckCircle, XCircle } from 'lucide-react';
import { toast } from 'sonner';
import Navbar from '@/components/Navbar';

const Signup = () => {
  const { signup, checkUsernameAvailability, isLoading, error } = useAuth();
  const navigate = useNavigate();
  
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [usernameAvailable, setUsernameAvailable] = useState<boolean | null>(null);
  const [isCheckingUsername, setIsCheckingUsername] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [usernameError, setUsernameError] = useState<string | null>(null);
  const [checkCounter, setCheckCounter] = useState(0);
  const lastCheckedUsername = useRef('');

  // Memoized username check function to avoid recursive calls
  const checkUsername = useCallback(async (usernameToCheck: string) => {
    if (!usernameToCheck || usernameToCheck.length < 3) return false;
    
    // Prevent redundant checks
    if (lastCheckedUsername.current === usernameToCheck) {
      return usernameAvailable || false;
    }
    
    try {
      lastCheckedUsername.current = usernameToCheck;
      const available = await checkUsernameAvailability(usernameToCheck);
      return available;
    } catch (err) {
      console.error("Error in checkUsername:", err);
      return false;
    }
  }, [checkUsernameAvailability, usernameAvailable]);
  
  // Check username availability with debounce
  useEffect(() => {
    // Reset errors when username changes
    setUsernameError(null);
    
    if (username.length < 3) {
      setUsernameAvailable(null);
      return;
    }
    
    // Validate username format first using regex
    const usernameRegex = /^[a-z0-9_]+$/;
    if (!usernameRegex.test(username)) {
      setUsernameError('Username can only contain lowercase letters, numbers, and underscores');
      setUsernameAvailable(false);
      return;
    }
    
    const timer = setTimeout(async () => {
      setIsCheckingUsername(true);
      try {
        console.log('Debounce check for username:', username, 'Check #:', checkCounter);
        const available = await checkUsername(username);
        console.log('Username available?', available);
        setUsernameAvailable(available);
        if (!available) {
          setUsernameError('Username is already taken');
        }
      } catch (err) {
        console.error("Error checking username:", err);
        setUsernameAvailable(false);
        setUsernameError('Error checking username availability');
      } finally {
        setIsCheckingUsername(false);
      }
    }, 500);
    
    return () => clearTimeout(timer);
  }, [username, checkUsername, checkCounter]);
  
  // Check if passwords match
  useEffect(() => {
    if (confirmPassword) {
      setPasswordsMatch(password === confirmPassword);
    } else {
      setPasswordsMatch(true);
    }
  }, [password, confirmPassword]);
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Final validation before submission
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    
    if (username.length < 3) {
      toast.error("Username must be at least 3 characters");
      return;
    }
    
    if (!email) {
      toast.error("Email is required");
      return;
    }
    
    if (password.length < 6) {
      toast.error("Password must be at least 6 characters");
      return;
    }
    
    // Force one last check of username availability before submission
    try {
      setIsCheckingUsername(true);
      const finalCheck = await checkUsername(username);
      setUsernameAvailable(finalCheck);
      setIsCheckingUsername(false);
      
      if (!finalCheck) {
        setUsernameError('Username is already taken');
        toast.error("Username is already taken");
        return;
      }
    } catch (err) {
      console.error("Final username check failed:", err);
      setIsCheckingUsername(false);
      toast.error("Error checking username availability");
      return;
    }
    
    console.log('Submitting signup form with username:', username);
    
    // We'll try to sign up
    await signup(username, email, password);
  };
  
  // Handle username change with validation
  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setUsername(value);
  };
  
  // Function to manually retry username check
  const retryUsernameCheck = () => {
    setCheckCounter(prev => prev + 1);
    lastCheckedUsername.current = ''; // Reset the cached check
  };

  // Floating emojis for background
  const floatingEmojis = [
    { emoji: "📸", delay: "0s", position: "top-20 left-[10%]" },
    { emoji: "💌", delay: "0.5s", position: "top-40 right-[15%]" },
    { emoji: "🤫", delay: "1.2s", position: "bottom-32 left-[20%]" },
    { emoji: "✨", delay: "1.8s", position: "bottom-40 right-[15%]" },
  ];

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#0a0a0a] p-4 relative overflow-hidden">
      {/* Floating Background Emojis */}
      {floatingEmojis.map((item, index) => (
        <div
          key={index}
          className={`absolute text-6xl md:text-7xl animate-float opacity-20 z-0 ${item.position}`}
          style={{ animationDelay: item.delay }}
        >
          {item.emoji}
        </div>
      ))}

      <div className="w-full max-w-md mx-auto relative z-10">
        <div className="text-center mb-8">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
            Create Your Account
          </h1>
          <p className="text-white/80 text-lg">
            Get your personal DropSnap link
          </p>
        </div>

        <Card className="bg-white/95 backdrop-blur-sm border-0 shadow-2xl">
          <CardContent className="p-6">
            <form onSubmit={handleSubmit} className="space-y-4">
              {error && (
                <div className="bg-red-50 p-3 rounded-lg text-red-500 text-sm">
                  {error}
                </div>
              )}
              
              <div className="space-y-2">
                <div className="flex justify-between items-center">
                  <Label htmlFor="username" className="text-gray-700 font-medium">Username</Label>
                  <span className="text-sm text-gray-500">
                    (This will be your unique link)
                  </span>
                </div>
                <div className="relative">
                  <Input
                    id="username"
                    className={`${
                      usernameError ? 'border-red-400 focus:ring-red-400' : 
                      usernameAvailable === true ? 'border-green-400 focus:ring-green-400' : ''
                    }`}
                    value={username}
                    onChange={handleUsernameChange}
                    placeholder="yourname"
                    required
                    minLength={3}
                    maxLength={20}
                  />
                  <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                    {isCheckingUsername ? (
                      <Loader2 size={18} className="animate-spin text-gray-400" />
                    ) : username.length >= 3 ? (
                      usernameAvailable ? (
                        <CheckCircle size={18} className="text-green-500" />
                      ) : (
                        <div className="flex items-center gap-1">
                          <XCircle size={18} className="text-red-500" />
                          <Button 
                            type="button" 
                            variant="ghost" 
                            size="sm" 
                            className="h-auto p-0 text-xs text-blue-500 hover:text-blue-700 hover:bg-transparent"
                            onClick={retryUsernameCheck}
                          >
                            Retry
                          </Button>
                        </div>
                      )
                    ) : null}
                  </div>
                </div>
                {usernameError && (
                  <p className="text-xs text-red-500">{usernameError}</p>
                )}
                <p className="text-sm text-gray-500">
                  Your link will be: dropsnap.link/{username || 'yourname'}
                </p>
              </div>
              
              <div className="space-y-2">
                <Label htmlFor="email" className="text-gray-700 font-medium">Email</Label>
                <Input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                />
              </div>
              
              <div className="space-y-2">
                <Label htmlFor="password" className="text-gray-700 font-medium">Password</Label>
                <Input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Create a password"
                  required
                  minLength={6}
                />
              </div>
              
              <div className="space-y-2">
                <Label htmlFor="confirmPassword" className="text-gray-700 font-medium">Confirm Password</Label>
                <Input
                  id="confirmPassword"
                  type="password"
                  className={!passwordsMatch ? 'border-red-400 focus:ring-red-400' : ''}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm your password"
                  required
                />
                {!passwordsMatch && (
                  <p className="text-xs text-red-500">Passwords do not match</p>
                )}
              </div>
              
              <Button
                type="submit"
                className="w-full bg-[#0a0a0a] hover:bg-black/80 text-white h-11 rounded-xl"
                disabled={
                  isLoading || 
                  isCheckingUsername || 
                  !passwordsMatch ||
                  !usernameAvailable ||
                  username.length < 3
                }
              >
                {isLoading ? (
                  <>
                    <Loader2 className="animate-spin h-4 w-4 mr-2" />
                    Creating account...
                  </>
                ) : (
                  'Sign Up'
                )}
              </Button>

              <div className="text-center pt-2">
                <p className="text-sm text-gray-600">
                  Already have an account?{' '}
                  <Link to="/login" className="text-blue-500 hover:text-blue-700 font-medium">
                    Log in
                  </Link>
                </p>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Signup;
