import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '@/context/AuthContext';
import { useSubmissionContext } from '@/context/SubmissionContext';
import Navbar from '@/components/Navbar';
import ShareLinkSection from '@/components/ShareLinkSection';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import { CheckCircle, LockIcon, Shield, RefreshCw } from 'lucide-react';
import { cleanupExpiredSubmissions } from '@/services/submissionService';
import SubmissionLimitBar from '@/components/SubmissionLimitBar';
import { toast } from 'sonner';
import SubmissionGrid from '@/components/SubmissionGrid';
import { Link } from 'react-router-dom';

// Threshold at which to start showing the limit warning (80% of limit)
const LIMIT_WARNING_THRESHOLD = 0.8;

const Dashboard = () => {
  const {
    user,
    refreshUserProfile
  } = useAuth();
  const {
    pendingSubmissions,
    approvedSubmissions,
    refreshSubmissions,
    visibleApprovedCount,
    blurredApprovedCount,
    submissionLimit,
    pendingReviewCount
  } = useSubmissionContext();
  const [activeTab, setActiveTab] = React.useState('approved'); // Default tab is approved
  const cleanupRunRef = useRef(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const refreshRequestedRef = useRef(false);

  // Effect to run cleanup on component mount only once
  useEffect(() => {
    // Only run if it hasn't run already
    if (!cleanupRunRef.current) {
      const runCleanup = async () => {
        try {
          await cleanupExpiredSubmissions();
          // Refresh submissions after cleanup
          refreshSubmissions();
        } catch (error) {
          console.error('Error during cleanup:', error);
        }
        cleanupRunRef.current = true;
      };
      runCleanup();
    }
    
    // Only log user info on initial mount, not every render
    if (!refreshRequestedRef.current) {
      console.log("Current user in Dashboard:", user);
      console.log("Is admin?", user?.isAdmin);
    }
  }, [refreshSubmissions]);
  
  const handleRefreshProfile = async () => {
    if (!refreshUserProfile) return;
    
    if (isRefreshing) return; // Prevent multiple refreshes
    
    setIsRefreshing(true);
    refreshRequestedRef.current = true;
    
    try {
      await refreshUserProfile();
      toast.success("Profile refreshed successfully");
    } catch (error) {
      console.error("Error refreshing profile:", error);
      toast.error("Failed to refresh profile");
    } finally {
      setIsRefreshing(false);
      // Reset the ref after a delay to allow potential refreshes later
      setTimeout(() => {
        refreshRequestedRef.current = false;
      }, 5000);
    }
  };
  
  // Calculate if we should show the limit warning
  const shouldShowLimitWarning = !user?.isPro && 
    (visibleApprovedCount / submissionLimit) >= LIMIT_WARNING_THRESHOLD;

  // Calculate if the limit has been reached
  const limitReached = !user?.isPro && blurredApprovedCount > 0;

  if (!user) {
    return null;
  }
  
  return (
    <div className="min-h-screen bg-[#0a0a0a] text-white">
      <Navbar />
      
      <div className="pt-24 pb-12 px-4 md:px-8">
        <div className="max-w-6xl mx-auto">
          {/* Header Section */}
          <div className="text-center mb-10">
            <h1 className="text-4xl md:text-5xl font-bold bg-gradient-to-r from-purple-500 via-pink-500 to-amber-500 bg-clip-text text-transparent mb-4">
              Welcome Back, {user.username}!
            </h1>
            <p className="text-white/80 text-lg">
              Ready to see what's dropped for you today?
            </p>
            <div className="mt-4 flex justify-center gap-2">
              {user.isAdmin === true && (
                <Link to="/admin">
                  <Button variant="outline" className="bg-white/5 border-white/10 text-white hover:bg-white/10">
                    <Shield size={16} className="mr-2" />
                    Admin Panel
                  </Button>
                </Link>
              )}
              
              <Button 
                variant="outline" 
                size="default"
                onClick={handleRefreshProfile}
                disabled={isRefreshing}
                className="bg-white/5 border-white/10 text-white hover:bg-white/10"
              >
                <RefreshCw size={16} className={`mr-2 ${isRefreshing ? 'animate-spin' : ''}`} />
                Refresh
              </Button>
            </div>
          </div>
          
          {/* Share Link Section - Full Width */}
          <div className="mb-8">
            <ShareLinkSection />
          </div>
          
          {/* Main Content Grid - Adjust layout based on whether we show the limit warning */}
          {!user.isPro && (shouldShowLimitWarning || limitReached) ? (
            // Original grid layout with sidebar when showing limit warning
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-10">
              {/* Left Column - Stats */}
              <div className="lg:col-span-1 space-y-6">
                <div className="dropsnap-card">
                  <SubmissionLimitBar className="mb-4" />
                  <div className="grid grid-cols-2 gap-4 text-center">
                    <div>
                      <p className="text-2xl font-bold text-white">{visibleApprovedCount}</p>
                      <p className="text-sm text-white/60">Visible Photos</p>
                    </div>
                    <div>
                      <p className="text-2xl font-bold text-white">{blurredApprovedCount}</p>
                      <p className="text-sm text-white/60">Blurred Photos</p>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Right Column - Submissions */}
              <div className="lg:col-span-2">
                <div className="dropsnap-card mb-6">
                  <Tabs defaultValue="approved" className="w-full">
                    <TabsList className="w-full bg-white/5 p-1">
                      <TabsTrigger 
                        value="approved"
                        className="flex-1 data-[state=active]:bg-white/10 data-[state=active]:text-white text-white/90"
                      >
                        Approved
                      </TabsTrigger>
                      <TabsTrigger 
                        value="pending"
                        className="flex-1 data-[state=active]:bg-white/10 data-[state=active]:text-white text-white/90"
                      >
                        Pending
                        {pendingReviewCount > 0 && (
                          <span className="ml-2 bg-amber-500 text-black text-xs font-bold px-2 py-0.5 rounded-full">
                            {pendingReviewCount}
                          </span>
                        )}
                      </TabsTrigger>
                    </TabsList>
                    <TabsContent value="approved" className="mt-4">
                      <SubmissionGrid submissions={approvedSubmissions} />
                    </TabsContent>
                    <TabsContent value="pending" className="mt-4">
                      <SubmissionGrid submissions={pendingSubmissions} />
                    </TabsContent>
                  </Tabs>
                </div>
              </div>
            </div>
          ) : (
            // Full width layout when NOT showing limit warning
            <div className="mb-10">
              <div className="dropsnap-card mb-6">
                <Tabs defaultValue="approved" className="w-full">
                  <TabsList className="w-full bg-white/5 p-1">
                    <TabsTrigger 
                      value="approved"
                      className="flex-1 data-[state=active]:bg-white/10 data-[state=active]:text-white text-white/90"
                    >
                      Approved
                    </TabsTrigger>
                    <TabsTrigger 
                      value="pending"
                      className="flex-1 data-[state=active]:bg-white/10 data-[state=active]:text-white text-white/90"
                    >
                      Pending
                      {pendingReviewCount > 0 && (
                        <span className="ml-2 bg-amber-500 text-black text-xs font-bold px-2 py-0.5 rounded-full">
                          {pendingReviewCount}
                        </span>
                      )}
                    </TabsTrigger>
                  </TabsList>
                  <TabsContent value="approved" className="mt-4">
                    <SubmissionGrid submissions={approvedSubmissions} />
                  </TabsContent>
                  <TabsContent value="pending" className="mt-4">
                    <SubmissionGrid submissions={pendingSubmissions} />
                  </TabsContent>
                </Tabs>
              </div>
            </div>
          )}
          
          {!user.isPro && (
            <div className="dropsnap-card bg-gradient-to-r from-purple-500/20 via-pink-500/20 to-amber-500/20 mb-8">
              <div className="flex items-center justify-between mb-6">
                <h3 className="font-bold text-2xl text-white">Enhance Your Experience</h3>
                <div className="bg-gradient-to-r from-purple-500 via-pink-500 to-amber-500 text-white text-xs font-bold px-3 py-1 rounded-full">
                  AVAILABLE
                </div>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
                <div className="flex items-start">
                  <CheckCircle size={20} className="text-green-400 mr-2 mt-1 flex-shrink-0" />
                  <span className="text-white">Download high-quality images</span>
                </div>
                <div className="flex items-start">
                  <CheckCircle size={20} className="text-green-400 mr-2 mt-1 flex-shrink-0" />
                  <span className="text-white">Unlimited submissions</span>
                </div>
                <div className="flex items-start">
                  <CheckCircle size={20} className="text-green-400 mr-2 mt-1 flex-shrink-0" />
                  <span className="text-white">No auto-deletion after 7 days</span>
                </div>
                <div className="flex items-start">
                  <CheckCircle size={20} className="text-green-400 mr-2 mt-1 flex-shrink-0" />
                  <span className="text-white">Priority review</span>
                </div>
              </div>
              
              <Link to="/upgrade">
                <Button className="w-full dropsnap-button">
                  Unlock Premium Features
                </Button>
              </Link>
            </div>
          )}
          
          {/* Privacy Card */}
          <div className="dropsnap-card">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="flex items-center mb-4 md:mb-0">
                <div className="w-10 h-10 rounded-full bg-white/5 flex items-center justify-center flex-shrink-0 mr-4">
                  <LockIcon size={20} className="text-white" />
                </div>
                <div>
                  <h3 className="font-medium text-white">Privacy Guaranteed</h3>
                  <p className="text-sm text-white/60">
                    Only you can see your submissions
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
