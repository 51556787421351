
import { useState, useEffect } from 'react';
import { toast } from 'sonner';
import { supabase } from '@/integrations/supabase/client';
import { useSubmissionContext } from '@/context/SubmissionContext';

export const useImageSubmission = (username: string | undefined) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [targetUserId, setTargetUserId] = useState<string | null>(null);
  const [isLoadingUserId, setIsLoadingUserId] = useState(true);
  const [caption, setCaption] = useState<string>('');
  const { submitImage, isLoading, hasReachedLimit } = useSubmissionContext();

  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  useEffect(() => {
    const fetchUserId = async () => {
      setIsLoadingUserId(true);
      try {
        if (!username) {
          toast.error("Invalid username");
          setIsLoadingUserId(false);
          return;
        }
        
        // Use the Supabase function to get the user ID by username
        const { data, error } = await supabase.rpc(
          'get_user_id_by_username',
          { username_param: username }
        );
        
        if (error) {
          toast.error("Error finding this user");
          setTargetUserId(null);
        } else if (data) {
          setTargetUserId(data);
        } else {
          toast.error(`The username @${username} doesn't exist on DropSnap.`);
          setTargetUserId(null);
        }
      } catch (error) {
        toast.error("Failed to verify username");
        setTargetUserId(null);
      } finally {
        setIsLoadingUserId(false);
      }
    };
    
    if (username) {
      fetchUserId();
    }
  }, [username]);

  const handleFileSelect = (file: File) => {
    // Check file type
    if (!file.type.startsWith('image/')) {
      toast.error("Please select an image file");
      return;
    }
    
    // Check file size (5MB limit)
    if (file.size > 5 * 1024 * 1024) {
      toast.error("Image must be less than 5MB");
      return;
    }
    
    setSelectedFile(file);
    const objectUrl = URL.createObjectURL(file);
    setPreviewUrl(objectUrl);
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      toast.error("Please select an image");
      return;
    }

    if (!username || !targetUserId) {
      toast.error("Invalid recipient");
      return;
    }
    
    try {
      await submitImage(targetUserId, selectedFile, caption);
      setIsSubmitted(true);
      toast.success(`Your image has been submitted to @${username}`);
    } catch (error: any) {
      toast.error(error.message || "Failed to submit image");
    }
  };

  const handleReset = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
    setIsSubmitted(false);
    setCaption('');
  };

  const handleCaptionChange = (value: string) => {
    setCaption(value);
  };

  return {
    selectedFile,
    previewUrl,
    isSubmitted,
    targetUserId,
    isLoadingUserId,
    isLoading,
    hasReachedLimit,
    caption,
    handleFileSelect,
    handleSubmit,
    handleReset,
    handleCaptionChange
  };
};
